import React from 'react'
import {useTranslation} from "react-i18next";
import { Button, Modal, Col } from 'react-bootstrap';

interface ModalDeleteMenuItemProps {
    showDeleteModal: boolean;
    setShowDeleteModal: (show: boolean) => void;
    deleteModalMessage: string;
    handleConfirmDelete: () => void;
}

const ModalDeleteMenuItem: React.FC<ModalDeleteMenuItemProps> = (props) => {
    const {t} = useTranslation();
    return (
        <Modal centered show={props.showDeleteModal} onHide={() => props.setShowDeleteModal(false)}>
            <Modal.Header closeButton className="border-0">
                <Modal.Title className="fs-5">{t("Deleting dish")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="mx-auto col-sm-10">
                    <p className="text-center mb-0">{props.deleteModalMessage}</p>
                </div>
            </Modal.Body>
            <Modal.Footer className="border-0">
                <Col>
                    <Button variant="outline-primary" className="w-100 h-100" onClick={() => props.setShowDeleteModal(false)}>
                        {t("cancel")}
                    </Button>
                </Col>
                <Col>
                    <Button variant="primary" className="w-100 fw-bold" onClick={props.handleConfirmDelete}>
                        {t("delete")}
                    </Button>
                </Col>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalDeleteMenuItem;
