import React, {ChangeEvent, useState} from 'react';
import {ErrorMessage, Field, useField, useFormikContext} from 'formik';
import {useTranslation} from 'react-i18next';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import {useGetCurrencyMenuItemQuery,} from '@me-team/host/src/modules/menu/graphql/menu.hooks';

interface PriceInputProps {
  name: string;
  placeholder: string;
  initialValue?: number;
  id: string;
}

const PriceInput: React.FC<PriceInputProps> = ({
                                                 name,
                                                 placeholder,
                                                 initialValue,
                                                 id
                                               }) => {
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
  const initialValuePrice = initialValue ? initialValue.toString() : ''
  const {setFieldValue, setFieldTouched, validateField} = useFormikContext();
  const [field, meta] = useField(name);
  const [price, setPrice] = useState(initialValuePrice);

  const {data: currenciesData} = useGetCurrencyMenuItemQuery()

  const currencySign = currenciesData?.user?.company?.currency?.currencySign

  const handlePriceChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const value = e.target.value.trim();
    const regex = /^(?!0\d)\d{0,6}(\.\d{0,2})?$/;
    if (regex.test(value) || value === '') {
      setPrice(value);
    }
  };

  const handleBlurPrice = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    if (value === "") {
      setPrice('');
      setFieldTouched(name, true, true)
      setFieldValue(name, null)
    } else {
      const numericPrice = parseFloat(value);

      if (!isNaN(numericPrice)) {
        const formattedPrice = numericPrice.toFixed(2);
        setPrice(formattedPrice);
        setFieldValue(name, parseFloat(formattedPrice));
      } else {
        setPrice('');
        setFieldValue(name, null);
      }
    }
  }
  return (
    <>
      <div className='position-relative'>
        <Field
          name={name}
          type='text'
          id={id}
          placeholder={placeholder}
          value={price !== null ? price.toString() : ''}
          className={`form-control ${meta.touched && meta.error ? 'is-invalid' : ''}`}
          onBlur={handleBlurPrice}
          onChange={handlePriceChange}
        />
        <i className={`position-absolute end-0 top-50 translate-middle-y text-dark me-3 mt-1 me-2 pe-3'
             ${meta.error ? 'd-none' : ''}`}>
          {currencySign}
        </i>
        <ErrorMessage name={name}>
          {msg => <div className='invalid-feedback'>{msg}</div>}
        </ErrorMessage>
      </div>
      {isErrorModalOpen ?
        <ErrorModalComponent
          i18n={i18next}
          onClose={() => {
            setIsErrorModalOpen(null)
          }}
          isOpen={!!isErrorModalOpen}
          currentError={isErrorModalOpen}
        /> : null
      }
    </>
  );
};

export default PriceInput;
