import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useReactiveVar } from '@apollo/client';
import { useCreateMenuCategoryMutation, useGetMenuCategoriesIconsQuery } from '@me-team/host/src/modules/menu/graphql/menu.hooks';
import { currentCompanyIdVar } from '@me-team/host/src/apollo/globalVar/state';
import DishesBranch from "../../images/dishes-branch.svg";
import EmployeeBranch from "../../images/employee-branch.svg";
import TariffBranch from "../../images/tariff-branch.svg";

interface Category {
    id: number;
    name: string;
}

interface CategoryFormModalProps {
    show: boolean;
    handleClose: () => void;
    category: Category | null;
    parentCategory: Category | null;
    onSave: () => void;
}

const CategoryFormModal: React.FC<CategoryFormModalProps> = (
    {
        show,
        handleClose,
        category,
        parentCategory,
        onSave,
    }
) => {
    const {t} = useTranslation();
    const basePath = process.env.REACT_APP_POS_URL;
    // State variables
    const [language, setLanguage] = useState<string>('en');
    const [name, setName] = useState<string>('');
    const [nameLength, setNameLength] = useState<number>(0);
    const [description, setDescription] = useState<string>('');
    const [descriptionLength, setDescriptionLength] = useState<number>(0);
    const [icon, setIcon] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);

    const {data: menuCategoriesIconsData, loading} = useGetMenuCategoriesIconsQuery({
        context: {
            errorType: "global",
        }
    })

    const categoriesIcons = menuCategoriesIconsData?.relations?.menuCategoryIcons;

    // Mutation hook
    const [createMenuCategory, {loading: creating, error: createError}] = useCreateMenuCategoryMutation()

    useEffect(() => {
        if (category) {
            setName(category.name);
            setNameLength(category.name.length);
        } else {
            setName('');
            setNameLength(0);
            setDescription('');
            setDescriptionLength(0);
            setIcon(null);
        }
    }, [category]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!name.trim()) {
            setErrorMessage(t('Fill in this field.'));
            return;
        }

        // Prepare input data
        const input = {
            name: name.trim(),
            description: description.trim() || null,
            parent: parentCategory?.id || null,
            favicon: icon || null,
        };

        try {
            await createMenuCategory({
                variables: {
                    companyId: parseInt(currentCompanyId),
                    input,
                },
            });

            onSave();
            handleClose();
        } catch (error: any) {
            console.error('Error creating category:', error);
            if (error.graphQLErrors && error.graphQLErrors.length > 0) {
                const serverMessage = error.graphQLErrors[0].message;
                if (serverMessage.includes('already exists')) {
                    setErrorMessage(t('A category with this name already exists.'));
                } else {
                    setErrorMessage(t('An error occurred while creating the category.'));
                }
            } else {
                setErrorMessage(t('An error occurred while creating the category.'));
            }
        }
    };

    const title = category
        ? t('Edit Category')
        : parentCategory
            ? t('Create New Category in {{parentName}}', {parentName: parentCategory.name})
            : t('Create New Category');

    // Define available icons, this is mock icons, need take it from Figma
    const availableIcons = [
        {name: 'Star', value: 'bi-star', IconComponent: DishesBranch},
        {name: 'Heart', value: 'bi-heart', IconComponent: EmployeeBranch},
        {name: 'Big', value: 'bi-big', IconComponent: TariffBranch},
    ];

    return (
        <Modal centered show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton className="border-bottom-0">
                <Modal.Title className="fs-5">{title}</Modal.Title>
                {/* When main language will be added*/}
                <div className="text-primary bg-violet-light rounded-1 fs-7 p-1 px-6 ms-3">Українська</div>
            </Modal.Header>
            <Form onSubmit={handleSubmit}>
                <Modal.Body className="border-bottom-0">
                    <div className="px-6 mb-3">
                        {/* Category Name */}
                        <Form.Group controlId="categoryName" className="mb-3">
                            <Row className="align-items-center">
                                <Col sm={3}>
                                    <Form.Label className="fs-7 fw-bold text-start">
                                        {t('Category Name')} <span className="text-danger ms-1">*</span>
                                    </Form.Label>
                                </Col>
                                <Col sm={9}>
                                    <Form.Control
                                        type="text"
                                        maxLength={50}
                                        placeholder={t('Enter a category name')}
                                        value={name}
                                        onChange={(e) => {
                                            setName(e.target.value);
                                            setNameLength(e.target.value.length);
                                            setErrorMessage('');
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Col sm={9} className="ms-auto">
                                <Form.Text className="text-grey fs-7 ps-2">{`${nameLength}/50`}</Form.Text>
                                {errorMessage && <div className="text-danger fs-7 ps-2">{errorMessage}</div>}
                            </Col>
                        </Form.Group>
                        {/* Description */}
                        <Form.Group controlId="categoryDescription" className="mb-3">
                            <Row className="align-items-center">
                                <Col sm={3}>
                                    <Form.Label className="fs-7 fw-bold text-start">{t('Description')}</Form.Label>
                                </Col>
                                <Col sm={9}>
                                    <Form.Control
                                        as="textarea"
                                        maxLength={400}
                                        placeholder={t('Enter a short category description (optional)')}
                                        value={description}
                                        onChange={(e) => {
                                            setDescription(e.target.value);
                                            setDescriptionLength(e.target.value.length);
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Col sm={9} className="ms-auto">
                                <Form.Text className="text-grey fs-7 ps-2">{`${descriptionLength}/400`}</Form.Text>
                            </Col>
                        </Form.Group>
                        {/* Category Icon */}
                        <Form.Group controlId="categoryIcon">
                            <Row>
                                <Col sm={3}>
                                    <Form.Label className="fs-7 fw-bold text-start pt-2">{t('Category Icon')}</Form.Label>
                                </Col>
                                <Col sm={9}>
                                    <div className="d-flex flex-wrap gap-3">
                                        {categoriesIcons?.map((iconOption) => {
                                            // const Icon = iconOption.IconComponent;
                                            return (
                                                <Button
                                                    key={iconOption.id}
                                                    variant="outline-secondary"
                                                    // variant={icon === iconOption.value ? 'primary' : 'outline-secondary'}
                                                    className="d-flex justify-content-center align-items-center p-2"
                                                    style={{width: "50px", height: "50px"}}
                                                    onClick={() => {
                                                        if (icon === iconOption.name) {
                                                            setIcon(null); // Deselect if clicked again
                                                        } else {
                                                            setIcon(iconOption.name);
                                                        }
                                                    }}
                                                >
                                                    {/* Render the SVG icon with specified size */}
                                                    <img src={`${basePath}/${iconOption.name}`} width={30} height={30}/>
                                                </Button>
                                            );
                                        })}
                                    </div>
                                </Col>
                            </Row>
                            <Col sm={9} className="ms-auto">
                                {creating && <div className="text-grey fs-7 ps-2">{t('Creating category...')}</div>}
                                {createError && <div className="text-danger fs-7 ps-2">{t('Error creating category.')}</div>}
                            </Col>
                        </Form.Group>
                    </div>
                </Modal.Body>
                <Modal.Footer className="justify-content-between border-top-0">
                    <Button variant="outline-primary" className="custom-btn" onClick={handleClose} disabled={creating}>
                        {t('Сancel')}
                    </Button>
                    <Button variant="primary" className="custom-btn fw-bold" type="submit" disabled={creating}>
                        {category ? t('Save') : t('Create')}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default CategoryFormModal;
