import React from 'react';
import ReactDropdownTreeSelect from 'react-dropdown-tree-select';

interface ReactSelectProps {
    id?: string;
    data: Object;
    onChange: () => void;
    texts: Object;
    showDropdown?: boolean | string;
    className?: string;
    showPartiallySelected?: boolean;
}

const CustomReactDropdownTreeSelect: React.FC<ReactSelectProps> = ({
                                                                       id,
                                                                       data,
                                                                       onChange,
                                                                       texts,
                                                                       showDropdown = "default",
                                                                       className,
                                                                       showPartiallySelected=true
                                                                   }) => {

    return (
        <>
            <ReactDropdownTreeSelect
                id={id}
                data={data}
                onChange={onChange}
                texts={texts}
                showDropdown={showDropdown}
                className="custom-dropdown-tree-select"
                showPartiallySelected={showPartiallySelected}
            />
        </>
    );
};

export default CustomReactDropdownTreeSelect;
