import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(HttpBackend) // использует HttpBackend для загрузки переводов
    .use(LanguageDetector) // обнаруживает язык пользователя
    .use(initReactI18next) // инициализирует react-i18next
    .init({
        fallbackLng: 'en', // запасной язык, если текущий не доступен
        debug: false, // выводит информацию для отладки в консоль
        detection: {
            order: ['queryString', 'cookie', 'localStorage', 'navigator', 'htmlTag'],
            caches: ['cookie'], // кэширует информацию о языке пользователя
        },
        backend: {
            // пути для загрузки переводов, `%{lng}` будет заменено на текущий язык
            loadPath: '/locales/{{lng}}/translation.json',
        },
        interpolation: {
            escapeValue: false, // не экранирует значения
        },
    });

export default i18n;
