import React, { useState } from 'react';
import { Modal, Button, Form, FormCheck } from 'react-bootstrap';
import {useTranslation} from "react-i18next";
import { useGetMenuSupplementsQuery, } from '@me-team/host/src/modules/menu/graphql/menu.hooks';

interface IDialogMenuSupplement {
    show: boolean;
    setShow: (b: boolean) => void;
    onCreateNew: any;
}

const DialogMenuSupplement = ({show, setShow, onCreateNew}: IDialogMenuSupplement) => {
    const {t} = useTranslation();
    const [search, setSearch] = useState<string>('')
    const {data} = useGetMenuSupplementsQuery({
        variables: {
            search
        }
    })

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(false);

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton className="border-bottom-0">
                    <Modal.Title className="fs-5">{t("Add additives to the dish")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="px-6">
                        <Form.Group className="mb-3" controlId="searchSupplement">
                            <Form.Control type="text" placeholder={t("Search")} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                            <div className="form-check form-switch mb-3">
                                <FormCheck
                                    type="checkbox"
                                    label="Тісто для піци (американо; італійське)"
                                    className="ps-0"
                                />
                            </div>
                            <div className="form-check form-switch mb-3">
                                <FormCheck
                                    type="checkbox"
                                    label={
                                            <p className="text-dark mb-0">Обрати соус (сметана<span className="fw-bold mx-1">+10 UAH;</span>
                                            часниковий<span className="fw-bold mx-1">+10 UAH;</span>
                                            томатний<span className="fw-bold mx-1">+10 UAH;</span>
                                            чілі<span className="fw-bold mx-1">+10 UAH)</span></p>
                                        }
                                    className="ps-0"
                                    />
                            </div>
                            <div className="form-check form-switch mb-3">
                                <FormCheck
                                    type="checkbox"
                                    label={
                                        <p className="text-dark mb-0">До борщу (булочка<span className="fw-bold mx-1">+5 UAH;</span>
                                            сметана<span className="fw-bold mx-1">+40 UAH;</span>
                                            часник<span className="fw-bold mx-1">+5 UAH)</span></p>
                                    }
                                    className="ps-0"
                                />
                            </div>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="justify-content-between border-top-0">
                    <Button variant="outline-primary" className="custom-btn" onClick={onCreateNew}>
                        {t("Create new")}
                    </Button>
                    <Button variant="primary" className="custom-btn fw-bold" onClick={handleClose}>
                        {t("Add")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default DialogMenuSupplement;
