import React, { useState } from 'react';
import {
    Table,
    Button,
    Row,
    Col,
    Dropdown,
    DropdownButton,
    Tooltip, OverlayTrigger,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {Link, useNavigate} from "react-router-dom";
import {
    Pagination,
    TableHeader,
    FiltersButton,
    ReactSelect,
    RenderEmptyList,
    BadgeAndButtonForArchive
} from '@services/ui-components';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import AddEditMenuModal from "./AddEditMenuModal";
import { hexToRgbaWithOpacity } from "../../hooks/helpers";
import emptyImage from "../../images/additives-empty-page.png";

interface Menu {
    id: number;
    name: string;
    type: string;
    mainLanguage: string;
    languages: string[];
    status: 'In Development' | 'Active' | 'Inactive';
    color: string;
    categoryCount: number;
    dishCount: number;
    archived: boolean;
}

const MenuPage: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Mock data
    const initialMenus: Menu[] = [
        {
            id: 1,
            name: 'Main Menu',
            type: 'For In-House Orders',
            mainLanguage: 'English',
            languages: ['English', 'Spanish'],
            status: 'Active',
            color: '#F8AC59',
            categoryCount: 5,
            dishCount: 25,
            archived: false,
        },
        {
            id: 2,
            name: 'Delivery Menu',
            type: 'For Delivery Orders',
            mainLanguage: 'English',
            languages: ['English'],
            status: 'In Development',
            color: '#1AB394',
            categoryCount: 3,
            dishCount: 15,
            archived: false,
        },
        {
            id: 3,
            name: 'Archived Menu',
            type: 'For Pickup Orders',
            mainLanguage: 'English',
            languages: ['English', 'French'],
            status: 'Inactive',
            color: '#DEDEDE',
            categoryCount: 4,
            dishCount: 20,
            archived: true,
        },
    ];

    // State variables
    const [menus, setMenus] = useState<Menu[]>(initialMenus);
    const [showFilters, setShowFilters] = useState<boolean>(false);
    const [filterName, setFilterName] = useState<string>('');
    const [filterType, setFilterType] = useState<string>('');
    const [currentPage, setCurrentPage] = useState<number>(1);
    const itemsPerPage = 10;

    // State for modals
    const [showAddEditModal, setShowAddEditModal] = useState<boolean>(false);
    const [modalMode, setModalMode] = useState<'add' | 'edit' | 'clone' | 'restore'>('add');
    const [selectedMenu, setSelectedMenu] = useState<Menu | null>(null);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

    // Toggle filters
    const toggleFilters = () => setShowFilters(!showFilters);

    // Handle filters
    const applyFilters = () => {
        let filteredMenus = initialMenus;
        if (filterName) {
            filteredMenus = filteredMenus.filter((menu) =>
                menu.name.toLowerCase().includes(filterName.toLowerCase())
            );
        }
        if (filterType) {
            filteredMenus = filteredMenus.filter((menu) => menu.type === filterType);
        }
        setMenus(filteredMenus);
        setCurrentPage(1);
    };

    const clearFilters = () => {
        setFilterName('');
        setFilterType(undefined);
        setMenus(initialMenus);
        setCurrentPage(1);
    };

    // Handle pagination
    const totalItems = menus.length;
    const pageCount = Math.ceil(totalItems / itemsPerPage);
    const paginatedMenus = menus.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    // Handle actions
    const handleAddMenu = () => {
        setModalMode('add');
        setSelectedMenu(null);
        setShowAddEditModal(true);
    };

    const handleEditMenu = (menu: Menu) => {
        setModalMode('edit');
        setSelectedMenu(menu);
        setShowAddEditModal(true);
    };

    const handleCloneMenu = (menu: Menu) => {
        setModalMode('clone');
        setSelectedMenu(menu);
        setShowAddEditModal(true);
    };

    const handleDeleteMenu = (menu: Menu) => {
        setSelectedMenu(menu);
        setShowDeleteModal(true);
    };

    const handleRestoreMenu = (menu: Menu) => {
        // Restore the menu
        const updatedMenus: any = menus.map((m) =>
            m.id === menu.id ? { ...m, archived: false, status: 'Active' } : m
        );
        setMenus(updatedMenus);
    };

    const handleConfirmDelete = () => {
        if (selectedMenu) {
            // Archive the menu
            const updatedMenus: any = menus.map((m) =>
                m.id === selectedMenu.id ? { ...m, archived: true, status: 'Inactive' } : m
            );
            setMenus(updatedMenus);
            setShowDeleteModal(false);
            setSelectedMenu(null);
        }
    };

    const handleSaveMenu = (menuData: any) => {
        if (modalMode === 'add') {
            // Add new menu
            const newMenu: Menu = {
                id: menus.length + 1,
                name: menuData.name,
                type: menuData.type,
                mainLanguage: menuData.mainLanguage,
                languages: menuData.languages,
                status: 'In Development',
                color: menuData.color,
                categoryCount: 0,
                dishCount: 0,
                archived: false,
            };
            setMenus([...menus, newMenu]);
        } else if (modalMode === 'edit' && selectedMenu) {
            // Edit existing menu
            const updatedMenus = menus.map((m) =>
                m.id === selectedMenu.id ? { ...m, ...menuData } : m
            );
            setMenus(updatedMenus);
        } else if (modalMode === 'clone' && selectedMenu) {
            // Clone menu
            const clonedMenu: Menu = {
                id: menus.length + 1,
                name: menuData.name,
                type: menuData.type,
                mainLanguage: selectedMenu.mainLanguage,
                languages: menuData.languages,
                status: 'In Development',
                color: menuData.color,
                categoryCount: selectedMenu.categoryCount,
                dishCount: selectedMenu.dishCount,
                archived: false,
            };
            setMenus([...menus, clonedMenu]);
        } else if (modalMode === 'restore' && selectedMenu) {
            // Restore menu
            const updatedMenus: any = menus.map((m) =>
                m.id === selectedMenu.id ? { ...m, archived: false, status: 'Active' } : m
            );
            setMenus(updatedMenus);
        }
        setShowAddEditModal(false);
        setSelectedMenu(null);
    };

    const isFilterNotEmpty = [filterName, filterType].some(value => value !== '' && value !== null);

    const optionsTypeMenu = [
        { value: 'For In-House Orders', label: t('For In-House Orders') },
        { value: 'For Delivery Orders', label: t('For Delivery Orders') },
        { value: 'For Pickup Orders', label: t('For Pickup Orders') },
    ];

    return (
        <div className="mb-4">
            {/*Correct the breadcrumb if needed, so it matches the design or requirements.*/}
            <TableHeader
                title={t('All menu')}
                paths={[{ path: "/branches", label: t("Branches") }, { path: "/menu/page", label: t("All menu") }]}
                buttons={
                    <Row className="gx-3 justify-content-end mt-3 mt-lg-0">
                        <Col xs={6} xl={7} xxl={5}>
                            <Button
                                variant="primary"
                                className="fw-bold w-100 rounded-1"
                                onClick={handleAddMenu}
                            >
                                <i className="bi bi-plus-lg me-2"></i>
                                <span className="text-truncate">{t('Add Menu')}</span>
                            </Button>
                        </Col>
                        <Col xs={6} xl={5} xxl={4}>
                            <FiltersButton filter={{name: filterName, type: filterType}} showFilters={showFilters} onClick={toggleFilters}/>
                        </Col>
                    </Row>
                }
                showFilters={showFilters}
                colSize={7}
                btnColSize={5}
            >
                {/* Filters */}
                {showFilters && (
                    <Row className="gx-4 pb-6">
                        <Col lg={8} xl={9}>
                            <Row className="gx-3">
                                <Col md={6}>
                                    <div className="position-relative mb-3 mb-lg-0">
                                        <input
                                            id="allMenu-name-field"
                                            type="text"
                                            className="form-control rounded-1"
                                            placeholder={t('Search by name')}
                                            value={filterName}
                                            onChange={(e) => setFilterName(e.target.value)}
                                        />
                                        <i className="bi bi-search position-absolute top-50 end-0 translate-middle-y text-dark me-3"></i>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <ReactSelect
                                        id="allMenu-type-field"
                                        value={optionsTypeMenu.find(option => option.value === filterType) || null}
                                        options={optionsTypeMenu}
                                        placeholder={t("Type of menu")}
                                        onChange={(option) => setFilterType(String(option.value))}
                                        isSearchable={true}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={4} xl={3}>
                            <Row className="gx-3 h-100">
                                <Col sm={6} className="mb-2 mb-sm-0">
                                    <Button
                                        variant="outline-dark"
                                        className="border-grey w-100 h-100 fw-normal rounded-1"
                                        onClick={clearFilters}
                                    >
                                        {t('Clear')}
                                    </Button>
                                </Col>
                                <Col sm={6}>
                                    <Button
                                        variant="primary"
                                        className="w-100 h-100 rounded-1 fw-normal px-2"
                                        onClick={applyFilters}
                                    >
                                        {t('Apply')}
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )}
            </TableHeader>
            {/* Menus Table */}
            <div className="table-responsive mb-4 scrollbar">
                {
                    paginatedMenus.length > 0 ? (
                        <Table>
                            <thead>
                            <tr className="fs-7">
                                <th className="ps-4">{t('Name')}</th>
                                <th>{t('type')}</th>
                                <th>{t('Language versions')}</th>
                                <th>{t('Main language')}</th>
                                <th>{t('Status')}</th>
                                <th>{t('Categories')}</th>
                                <th>{t('Dishes')}</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {paginatedMenus.map((menu: Menu) => {
                                const colorForArchive = menu.archived ? 'text-secondary' : 'text-dark';
                                return (
                                    <tr key={menu.id} className={menu.archived ? 'text-muted' : ''}>
                                    <td className="ps-4">
                                        <span className={menu.archived ? 'text-secondary' : 'text-primary'}>{menu.name}</span>
                                        {!menu.archived && (
                                            <Button
                                                variant="link"
                                                className="p-0 ms-2"
                                                onClick={() => handleEditMenu(menu)}
                                            >
                                                <i className="bi bi-pencil-square" aria-hidden="true"></i>
                                            </Button>
                                        )}
                                    </td>
                                    <td className={colorForArchive}>
                                        <span>{menu.type}</span>
                                        <OverlayTrigger
                                            // TODO: add right text for tooltip
                                            overlay={<Tooltip>{t('Lorem')}</Tooltip>}>
                                            <i className="bi bi-info-circle ms-2 fs-7 pt-1"></i>
                                        </OverlayTrigger>
                                    </td><td className={colorForArchive}>{menu.languages.join(', ')}</td>
                                    <td className={colorForArchive}>{menu.mainLanguage}</td>
                                    <td>
                                        <div className="d-inline-block py-1 px-2 rounded"
                                             style={{backgroundColor: menu.archived ? "#F5F5F5" : hexToRgbaWithOpacity(menu?.color), color: menu.archived ? "#DEDEDE" : menu?.color}}
                                        >
                                            <span className="fs-7 text-nowrap">{menu.status}</span>
                                        </div>
                                    </td>
                                    <td>
                                        {menu.categoryCount !== 0 ?
                                            <Link to={"#"}
                                                  className={`text-decoration-none ${menu.archived ? 'text-secondary' : 'text-primary'}`}>{menu.categoryCount}</Link>
                                            : menu.categoryCount}
                                    </td>
                                    <td>
                                        {menu.dishCount !== 0 ?
                                            <Link to={"#"}
                                                  className={`text-decoration-none ${menu.archived ? 'text-secondary' : 'text-primary'}`}>{menu.dishCount}</Link>
                                            : menu.dishCount}
                                    </td>
                                    <td>
                                        <div className="d-flex gap-2 justify-content-end align-items-center">
                                            {!menu.archived ? (
                                                <>
                                                    <OverlayTrigger overlay={<Tooltip>{t('Edit')}</Tooltip>}>
                                                        <Button
                                                            variant="outline-secondary"
                                                            className="py-2"
                                                            onClick={() => navigate(`/menu/page/${menu.id}`)}
                                                        >
                                                            <i className="bi bi-pencil text-dark"></i>
                                                        </Button>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger overlay={<Tooltip>{t('View')}</Tooltip>}>
                                                        <Button
                                                            variant="outline-secondary"
                                                            className="py-2"
                                                            onClick={() => console.log('View Menu')}
                                                        >
                                                            <i className="bi bi-eye text-dark"></i>
                                                        </Button>
                                                    </OverlayTrigger>
                                                    <DropdownButton
                                                        id={`dropdown-${menu.id}`}
                                                        title={<i className="bi bi-three-dots text-dark"></i>}
                                                        variant="outline-secondary"
                                                        align="end"
                                                        className="dropdown-menu-table"
                                                    >
                                                        <Dropdown.Item onClick={() => handleCloneMenu(menu)} className="ps-4 mb-1">
                                                            <i className="bi bi-stickies me-2"></i>
                                                            <span>{t('Clone')}</span>
                                                        </Dropdown.Item>
                                                        <Dropdown.Item onClick={() => console.log('Generate QR')} className="ps-4 mb-1">
                                                            <i className="bi bi-qr-code-scan me-2"></i>
                                                            <span>{t('Generate QR')}</span>
                                                        </Dropdown.Item>
                                                        <Dropdown.Item onClick={() => handleDeleteMenu(menu)} className="ps-4 mb-1">
                                                            <i className="bi bi-trash3 me-2"></i>
                                                            <span>{t('Archive menu')}</span>
                                                        </Dropdown.Item>
                                                    </DropdownButton>
                                                </>
                                            ) : (
                                                <BadgeAndButtonForArchive
                                                    onButtonClick={() => handleRestoreMenu(menu)}
                                                />
                                            )}
                                        </div>
                                    </td>
                                </tr>)
                            })}
                            </tbody>
                        </Table>
                    ) : (
                        <RenderEmptyList
                            isFilterNotEmpty={isFilterNotEmpty}
                            imageSrc={emptyImage}
                            altText="Dishes"
                            emptyFilterTitle='No data found for the given values'
                            emptyListTitle="You don't have any menu yet. Create your first menu"
                            buttonName={
                                <span className="fw-bold">
                                    <i className='bi bi-plus-lg fw-bold me-1 w-100'></i>
                                    {t("Add Menu")}
                                </span>
                            }
                            // onClickButton={() => navigate("/branch/menu/create-item")}
                        />
                    )
                }
            </div>
            {/* Pagination */}
            {pageCount > 1 ? (
                <div className="d-flex justify-content-between align-items-center">
                    <Pagination
                        t={t}
                        pages={pageCount}
                        currentPage={currentPage}
                        onPageChange={(page) => setCurrentPage(page)}
                        firstItemIndex={(currentPage - 1) * itemsPerPage + 1}
                        lastItemIndex={Math.min(currentPage * itemsPerPage, totalItems)}
                        totalItems={totalItems}
                    />
                </div>
            ) : null}
            {/* Add/Edit Menu Modal */}
            {showAddEditModal && (
                <AddEditMenuModal
                    show={showAddEditModal}
                    handleClose={() => {
                        setShowAddEditModal(false);
                        setSelectedMenu(null);
                    }}
                    onSave={handleSaveMenu}
                    mode={modalMode}
                    menu={selectedMenu}
                />
            )}
            {/* Delete Confirmation Modal */}
            {showDeleteModal && selectedMenu && (
                <ConfirmDeleteModal
                    show={showDeleteModal}
                    handleClose={() => {
                        setShowDeleteModal(false);
                        setSelectedMenu(null);
                    }}
                    onConfirm={handleConfirmDelete}
                    menuName={selectedMenu.name}
                />
            )}
        </div>
    );
};

export default MenuPage;
