import * as Yup from 'yup';
import {TFunction} from 'i18next';
import {MenuCategoryProxy} from '@me-team/host/graphql/types';

export interface IStep {
    key: string;
    label: string;
}

export interface IPickedCategoryNames {
    id: number;
    name: string;
    parentName: string | null;
}

export const getSteps = (t: any): IStep[] => [
    { key: 'menu', label: t('menu') },
    { key: 'category', label: t('Category') },
    { key: 'dish', label: t('Dishes') },
    { key: 'view', label: t('Preview') },
    { key: 'generate', label: t('Code generation') },

]

export const getCategoryName = (categoryData?: MenuCategoryProxy): string => {
    return categoryData?.parentCategory?.name
      ? categoryData.parentCategory.name
      : categoryData?.name;
};

export const getSubCategoryName = (categoryData?: MenuCategoryProxy): string | null => {
    return categoryData?.parentCategory?.name
      ? categoryData.name || null
      : null;
};

export const getInitialDishName = (name: string, t: TFunction) => {
    const prefix = t('Copy');
    if (!name) return '';
    return name.startsWith(`${prefix} -`) ? name : `${prefix} - ${name}`;
};

export const validationCloneDishSchema = (t: TFunction) =>
  Yup.object().shape({
    name: Yup.string().required(t('validation.required')),
    categories: Yup.array().min(1, t('validation.required')),
  })

