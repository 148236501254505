import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';


type EmptyListPropsType = {
    children?: React.ReactNode;
    title: string;
    imageSrc?: string;
    altText?: string
};

const EmptyList: React.FC<EmptyListPropsType> = ({ children, title, imageSrc, altText }) => {
    const { t } = useTranslation();

    return (
        <>
            <Row className='w-100 justify-content-md-center'>
                {imageSrc && (
                    <div className="text-center" style={{paddingTop: "75px"}}>
                        <img src={imageSrc} alt={altText ? t(altText) : null} className="img-fluid" />
                    </div>
                )}
                <Col
                    md={5}
                    className='mt-5'>
                    <h5 className='text-center font-weight-bold'>{t(title)}</h5>
                </Col>
            </Row>
            <Row className='w-100 justify-content-md-center'>
                <Col md={3}>
                    {children}
                </Col>
            </Row>
        </>
    );
};

export default EmptyList;