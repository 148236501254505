import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {useFormik, FieldArray, FormikProvider} from 'formik';
import * as Yup from 'yup';
import ReactDropdownTreeSelect from 'react-dropdown-tree-select';
import { CustomReactDropdownTreeSelect } from "@services/ui-components";

interface Dish {
    id: number;
    name: string;
    category: string;
    subcategory?: string;
}

interface AdditiveGroupFormModalProps {
    show: boolean;
    handleClose: () => void;
    onSave: () => void;
    language: string;
    dishesData: Dish[];
    existingGroup?: any;
}

const AdditiveGroupFormModal: React.FC<AdditiveGroupFormModalProps> = (
    {
        show,
        handleClose,
        onSave,
        language,
        dishesData,
        existingGroup
    }) => {
    const {t} = useTranslation();
    const [selectedDishes, setSelectedDishes] = useState<string[]>(
        existingGroup ? existingGroup?.items?.map(item => item.id.toString()) : []
    );
    // Prepare the tree data structure from dishesData
    const prepareTreeData = (dishes: Dish[]) => {
        const categories: any = {};

        dishes.forEach((dish) => {
            const {category, subcategory, name, id} = dish;

            if (!categories[category]) {
                categories[category] = {
                    label: category,
                    value: category,
                    children: {},
                };
            }

            if (subcategory) {
                if (!categories[category].children[subcategory]) {
                    categories[category].children[subcategory] = {
                        label: subcategory,
                        value: `${category}-${subcategory}`,
                        children: [],
                    };
                }

                categories[category].children[subcategory].children.push({
                    label: name,
                    value: id.toString(),
                });
            } else {
                if (!categories[category].children[name]) {
                    categories[category].children[name] = {
                        label: name,
                        value: id.toString(),
                    };
                }
            }
        });

        // Convert categories object to array
        const treeData: any = Object.values(categories).map((category) => {
            const categoryChildren: any = category.children;
            const subcategories: any = Object.values(categoryChildren).map((subcat: any) => {
                if (subcat.children) {
                    return {
                        label: subcat.label,
                        value: subcat.value,
                        children: subcat.children,
                    };
                } else {
                    return subcat;
                }
            });

            return {
                label: category.label,
                value: category.value,
                children: subcategories,
            };
        });

        return treeData;
    };

    const treeData = prepareTreeData(dishesData);

    // Initial form values
    const initialValues: any = existingGroup
        ? {
            groupName: existingGroup?.groupName,
            options: existingGroup?.options?.map((option: any) => ({
                name: option?.name,
                price: option?.price.toString(),
            })),
            isMandatory: existingGroup?.isRequireOnOrder,
            maxSelectable: existingGroup?.maxCountOnOrder,
        }
        : {
            groupName: '',
            options: [
                {name: '', price: ''},
                {name: '', price: ''},
            ],
            isMandatory: false,
            maxSelectable: 1,
        };


    // Validation schema
    const validationSchema = Yup.object().shape({
        groupName: Yup.string()
            .required(t('Group name is required.'))
            .max(50, t('Group name cannot exceed 50 characters.')),
        options: Yup.array()
            .of(
                Yup.object().shape({
                    name: Yup.string()
                        .required(t('Option name is required.'))
                        .max(50, t('Option name cannot exceed 50 characters.')),
                    price: Yup.number()
                        .typeError(t('Price must be a number.'))
                        .required(t('Price is required.'))
                        .min(0, t('Price cannot be negative.'))
                        .max(999999.99, t('Price cannot exceed 999999.99.')),
                })
            )
            .min(1, t('At least one option is required.')),
        maxSelectable: Yup.number()
            .required(t('Maximum number to select is required.'))
            .min(1, t('Must select at least one option.'))
            .test(
                'maxSelectable',
                t('Cannot be greater than the number of options.'),
                function (value) {
                    return value <= this.parent?.options?.length;
                }
            ),
        selectedDishes: Yup.array().min(1, t('At least one dish must be selected.')),
    });

    // Formik hook
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            if (selectedDishes?.length === 0) {
                console.log('err')
                return;
            } else {
                console.log('err')
            }
            const formValues = { ...values, selectedDishes };
            if (existingGroup) {
                // Update existing group logic
            } else {
                // Create new group logic
            }
            onSave();
            handleClose();
        },
    });

    // Reset form when modal is opened or language changes
    useEffect(() => {
        if (show) {
            formik.resetForm({values: initialValues});
            setSelectedDishes(
                existingGroup ? existingGroup?.items?.map(item => item.id.toString()) : []
            );
        }
    }, [show, language, existingGroup]);


    // Handle tree select change
    // const handleTreeChange = (currentNode, selectedNodes) => {
    //     const selectedValues = selectedNodes
    //         .filter((node) => !node.children) // Exclude categories and subcategories
    //         .map((node) => node.value);
    //     formik.setFieldValue('selectedDishes', selectedValues);
    // };

    // Remove a selected dish
    const removeDish = (dishValue: any) => {
        const updatedDishes = formik.values.selectedDishes.filter(
            (value: any) => value !== dishValue
        );
        formik.setFieldValue('selectedDishes', updatedDishes);
    };

    // Get dish label by value
    const getDishLabelByValue: any = (value: any, nodes = treeData) => {
        for (const node of nodes) {
            if (node.value === value) {
                return node.label;
            }
            if (node.children) {
                const childResult: any = getDishLabelByValue(value, node.children);
                if (childResult) {
                    return childResult;
                }
            }
        }
        return value; // Return the value if label not found
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton className="border-bottom-0">
                <Modal.Title className="fs-5">{t("New additives group")}</Modal.Title>
                {/* When main language will be added*/}
                <div className="text-primary bg-violet-light rounded-1 fs-7 p-1 px-6 ms-3">Українська</div>
            </Modal.Header>
            <FormikProvider value={formik}>
                <Form noValidate onSubmit={formik.handleSubmit}>
                    <Modal.Body>
                        <div className="px-6">
                            {/* Group Name */}
                            <Form.Group controlId="groupName" className="mb-4">
                                <Row>
                                    <Col sm={4}>
                                        <Form.Label htmlFor="createAdditive-name-field" className="fs-7 fw-bold text-start mb-0">{t("Additive group name")}
                                            <span className="text-danger ms-1">*</span>
                                        </Form.Label>
                                    </Col>
                                    <Col sm={8}>
                                        <Form.Control
                                            id="createAdditive-name-field"
                                            type="text"
                                            maxLength={50}
                                            placeholder={t('Add additive group name')}
                                            {...formik.getFieldProps('groupName')}
                                            isInvalid={
                                                !!formik.errors.groupName && formik.touched.groupName
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid" className="fs-7 py-1 px-2 text-danger m-0">
                                            {formik.errors.groupName}
                                        </Form.Control.Feedback>
                                        {/*<Form.Text className="fs-7 py-1 px-2 text-grey m-0">{`${formik?.values?.groupName?.length}/50`}</Form.Text>*/}
                                    </Col>
                                </Row>
                            </Form.Group>
                            {/* Select Dishes */}
                            <Form.Group controlId="dishes" className="mt-3">
                                <Row className="align-items-center">
                                    <Col sm={4}>
                                        <Form.Label htmlFor="createAdditive-dish-field" className="fs-7 fw-bold text-start mb-0">{t("Dishes")}</Form.Label>
                                    </Col>
                                    <Col sm={8}>
                                        <CustomReactDropdownTreeSelect
                                            id="createAdditive-dish-field"
                                            showDropdown={true}
                                            data={treeData}
                                            texts={{placeholder: t('Search Dishes')}}
                                            onChange={() => {}}
                                        />
                                        {formik.touched.selectedDishes && formik.errors.selectedDishes && (
                                            <div className="invalid-feedback d-block">
                                                {formik.errors.selectedDishes}
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                            </Form.Group>
                            {/* Display Selected Dishes */}
                            <div className="selected-dishes mt-2">
                                {formik?.values?.selectedDishes?.slice(0, 5).map((dishValue: any) => (
                                    <span key={dishValue} className="badge bg-secondary me-1 mb-1">
                                        {getDishLabelByValue(dishValue)}
                                        <button
                                            type="button"
                                            onClick={() => removeDish(dishValue)}
                                            className="btn btn-sm btn-light ms-1"
                                            style={{lineHeight: '1'}}
                                        >×</button>
                                    </span>
                                ))}
                                {formik?.values?.selectedDishes?.length > 5 && (<span>{t('More')} {formik?.values?.selectedDishes?.length - 5}</span>)}
                            </div>
                            {/* Options List */}
                            <div className="mt-4">
                                <h5 className="fs-16 mb-3">{t("List of additive/option variants")}
                                    <span className="text-danger ms-1">*</span>
                                </h5>
                                <FieldArray
                                    name="options"
                                    render={(arrayHelpers) => (
                                        <div>
                                            {formik.values.options.map((option, index) => (
                                                <div key={index} className="mb-3">
                                                    <Row className="mb-3">
                                                        <Col sm={4}>
                                                            <Form.Label htmlFor="createAdditive-option-field" className="fs-7 fw-bold text-start mb-0">{t("Additive/ option name")}</Form.Label>
                                                        </Col>
                                                        <Col sm={8}>
                                                            <Form.Group controlId={`options.${index}.name`}>
                                                                <Form.Control
                                                                    type="text"
                                                                    id="createAdditive-option-field"
                                                                    maxLength={50}
                                                                    className="fs-7 text-start mb-0"
                                                                    placeholder={t('Add name')}
                                                                    {...formik.getFieldProps(
                                                                        `options.${index}.name`
                                                                    )}
                                                                    isInvalid={
                                                                        !!formik.errors.options &&
                                                                        !!formik.errors.options[index]?.name &&
                                                                        formik.touched.options &&
                                                                        !!formik.touched.options[index]?.name
                                                                    }
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {formik.errors.options &&
                                                                        formik.errors.options[index]?.name}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row className="align-items-center mb-3">
                                                        <Col sm={4}>
                                                            <Form.Label htmlFor="createAdditive-price-field" className="fs-7 fw-bold text-start mb-0">{t("Option price")}</Form.Label>
                                                        </Col>
                                                        <Col md={8} className="d-flex">
                                                            <Form.Group controlId={`options.${index}.price`} className="w-100">
                                                                <InputGroup>
                                                                    <Form.Control
                                                                        type="number"
                                                                        min="0"
                                                                        step="0.01"
                                                                        placeholder={t('Option Price')}
                                                                        {...formik.getFieldProps(
                                                                            `options.${index}.price`
                                                                        )}
                                                                        className={`${!!formik.errors.options &&
                                                                        !!formik.errors.options[index]?.price &&
                                                                        formik.touched.options &&
                                                                        !!formik.touched.options[index]?.price ? "border-end border-danger" : "border-end-0"}`}
                                                                        isInvalid={
                                                                            !!formik.errors.options &&
                                                                            !!formik.errors.options[index]?.price &&
                                                                            formik.touched.options &&
                                                                            !!formik.touched.options[index]?.price
                                                                        }
                                                                    />
                                                                    <InputGroup.Text className="fs-16 rounded-end-1 text-dark bg-transparent">$</InputGroup.Text>
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {formik.errors.options &&
                                                                            formik.errors.options[index]?.price}
                                                                    </Form.Control.Feedback>
                                                                </InputGroup>
                                                            </Form.Group>
                                                            {formik?.values?.options?.length > 1 && index >= 1 && (
                                                                <div className="ms-2">
                                                                    <Button variant="outline-secondary" className="w-100 px-3 py-6 fs-normal rounded-1 align-self-start" onClick={() => remove(index)}>
                                                                        <i className="bi bi-trash3 text-dark"></i>
                                                                    </Button>
                                                                </div>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            ))}
                                            <Col sm={8} className="ms-auto ps-2">
                                                <Button type="button" variant="light"
                                                        className="fs-7 text-primary p-0 border-0 bg-transparent" onClick={() => arrayHelpers.push({ name: '', price: '' })}>
                                                    <i className="bi bi-plus me-1"></i>
                                                    {t("Add another additive variant or option")}
                                                </Button>
                                            </Col>
                                        </div>
                                    )}
                                />
                            </div>
                            {/* Choice When Ordering */}
                            <Form.Group controlId="isMandatory" className="mt-4">
                                <Row className="align-items-center">
                                    <Col sm={4}>
                                        <Form.Label htmlFor="createAdditive-enableChoice-field" className="fs-7 fw-bold text-start mb-0">{t("Selection when ordering")}</Form.Label>
                                    </Col>
                                    <Col sm={8}>
                                        <Form.Check
                                            id="createAdditive-enableChoice-field"
                                            type="switch"
                                            label={
                                                formik.values.isMandatory ? t('Mandatory') : t('Optional')
                                            }
                                            checked={formik.values.isMandatory}
                                            onChange={formik.handleChange}
                                            name="isMandatory"
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>
                            {/* Maximum Number to Select */}
                            <Form.Group controlId="maxSelectable" className="mt-3">
                                <Row>
                                    <Col sm={4}>
                                        <Form.Label htmlFor="createAdditive-maxChoice-field" className="fs-7 fw-bold text-start mb-0">
                                            {t('Max selection quantity')}
                                            <span className="text-danger">*</span>
                                        </Form.Label>
                                    </Col>
                                    <Col sm={8}>
                                        <Form.Control
                                            id="createAdditive-maxChoice-field"
                                            type="number"
                                            min="1"
                                            max={formik?.values?.options?.length}
                                            {...formik.getFieldProps('maxSelectable')}
                                            isInvalid={
                                                !!formik.errors.maxSelectable && formik.touched.maxSelectable
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.maxSelectable}
                                        </Form.Control.Feedback>
                                        {/*<Form.Text className="text-muted">*/}
                                        {/*    {t('Cannot be greater than the number of options.')}*/}
                                        {/*</Form.Text>*/}
                                    </Col>
                                </Row>
                            </Form.Group>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-between border-top-0">
                        <Button variant="outline-primary" className="custom-btn" onClick={handleClose}>{t("cancel")}</Button>
                        <Button type="submit" variant="primary" className="custom-btn fw-bold">{t('Save')}</Button>
                    </Modal.Footer>
                </Form>
            </FormikProvider>
        </Modal>
    );
};

export default AdditiveGroupFormModal;
