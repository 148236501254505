import {MenuCategoryProxy} from '@me-team/host/graphql/types';
import {CategoryInterface, SubcategoryInterface} from '../ServicesCategoryNewInnit';

class CategoryMeMenu {
  public static categoryModuleCreator = (meMenuCategories : MenuCategoryProxy[], categoryId : number | null, subcategoryId : number | null) : CategoryInterface[] => {
    const isActiveCategories = meMenuCategories?.filter((category) => !category?.deleted);

    return  isActiveCategories.map((meMenuCategory) => {
      return {
        id: meMenuCategory.id,
        name: meMenuCategory.name,
        isOpen: !!((meMenuCategory.id === categoryId) && subcategoryId),
        isPicked: !!((meMenuCategory.id === categoryId) && !subcategoryId),
        isSubcategoryPiked : !!((meMenuCategory.id === categoryId) && subcategoryId),
        subcategoryList: this.subcategoryModuleCreator(meMenuCategory.childCategories, subcategoryId)
      }
    })
  }

  public static subcategoryModuleCreator = (subcategories : MenuCategoryProxy[], currentSubcategoryId: number) : SubcategoryInterface[] => {
    const isActiveSubcategories = subcategories?.filter((subcategory) => !subcategory?.deleted)
    return isActiveSubcategories.map((subcategory) => {
      return {
        id: subcategory.id,
        name: subcategory.name,
        isPicked: subcategory.id === currentSubcategoryId
      }
    })
  }

}

export default CategoryMeMenu