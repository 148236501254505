import React from "react";
import { useTranslation } from 'react-i18next';
import {Button, Col, Modal} from "react-bootstrap";

interface ConfirmDeleteModalProps {
    show: boolean;
    handleClose: () => void;
    onConfirm: () => void;
    menuName: string;
}

const ConfirmDeleteModal: React.FC<ConfirmDeleteModalProps> = ({
                                                                   show,
                                                                   handleClose,
                                                                   onConfirm,
                                                                   menuName,
                                                               }) => {
    const { t } = useTranslation();

    return (
        <Modal centered show={show} onHide={handleClose}>
            <Modal.Header closeButton className="border-0">
                <Modal.Title className="fs-5">{t("Archive menu")}{" "}{menuName}</Modal.Title>
            </Modal.Header>
            <Modal.Body><div className="mx-auto col-sm-10">
                <p className="text-center mb-0">
                    {t("Are you sure you want to delete the menu? The menu will be moved to the archive, and you will be able to restore it at any time.")}
                </p>
            </div>
            </Modal.Body>
            <Modal.Footer className="border-0">
                <Col>
                    <Button variant="outline-primary" className="w-100 h-100" onClick={handleClose}>
                        {t("cancel")}
                    </Button>
                </Col>
                <Col>
                    <Button variant="primary" className="w-100 fw-bold" onClick={onConfirm}>
                        {t("Delete Menu")}
                    </Button>
                </Col>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmDeleteModal;