import React, { ReactNode } from 'react';
import {Button} from "react-bootstrap";

interface InfoBannerProps {
    children?: ReactNode;
    classIcon?: string;
    backgroundColor?: string;
    textColor?: string;
    isCloseButton?: boolean;
}

const InfoBanner: React.FC<InfoBannerProps> = ({
                                                   children,
                                                   classIcon = "bi-info-circle",
                                                   backgroundColor = "violet-light",
                                                   textColor = "violet-hover",
                                                   isCloseButton,
}) => {

    return (
        <div className={`d-flex gap-2 px-6 py-2 mb-4 rounded bg-${backgroundColor} text-${textColor} rounded-1`}>
            <i className={`bi ${classIcon} fs-normal`}></i>
            <div className='fs-7'>
                {children}
            </div>
            {isCloseButton ? <Button variant="link" className={`ms-auto fs-16 p-0 text-${textColor}`}><i className="bi bi-x-lg"></i></Button> : null}
        </div>
    );
};

export default InfoBanner;
