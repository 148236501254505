import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Form, Modal, Row, Col, OverlayTrigger, Popover, Tooltip} from "react-bootstrap";
import  {ReactSelect} from '@services/ui-components';


interface Menu {
    id: number;
    name: string;
    type: string;
    mainLanguage: string;
    languages: string[];
    status: 'In Development' | 'Active' | 'Inactive';
    color: string;
    categoryCount: number;
    dishCount: number;
    archived: boolean;
}
interface AddEditMenuModalProps {
    show: boolean;
    handleClose: () => void;
    onSave: (menuData: any) => void;
    mode: 'add' | 'edit' | 'clone' | 'restore';
    menu: Menu | null;
}

const AddEditMenuModal: React.FC<AddEditMenuModalProps> = ({
                                                               show,
                                                               handleClose,
                                                               onSave,
                                                               mode,
                                                               menu,
                                                           }) => {
    const { t } = useTranslation();

    // Form state
    const [menuName, setMenuName] = useState<string>(menu ? menu.name : '');
    const [menuType, setMenuType] = useState<string>(menu ? menu.type : '');
    const [languages, setLanguages] = useState<string[]>(menu ? menu.languages : ['English']);
    const mainLanguage = 'English'; // Assume main language is English

    // Title and button text based on mode
    let title = '';
    let saveButtonText = '';

    switch (mode) {
        case 'add':
            title = t('New Menu');
            saveButtonText = t('Create');
            break;
        case 'edit':
            title = t('Edit Menu');
            saveButtonText = t('Save');
            break;
        case 'clone':
            title = t(`Clone Menu "${menu?.name}"`);
            saveButtonText = t('Clone');
            // Pre-fill name with "Copy - Original Name"
            if (!menuName.startsWith('Copy - ')) {
                setMenuName(`Copy - ${menu?.name}`);
            }
            break;
        case 'restore':
            title = t('Restore Menu');
            saveButtonText = t('Restore');
            break;
        default:
            break;
    }

    // Handle form submission
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const menuData = {
            name: menuName,
            type: menuType,
            mainLanguage,
            languages,
        };
        onSave(menuData);
    };

    const optionsTypeMenu = [
        { value: 'For In-House Orders', label: t('For In-House Orders') },
        { value: 'For Delivery Orders', label: t('For Delivery Orders') },
        { value: 'For Pickup Orders', label: t('For Pickup Orders') },
    ];

    const optionsLanguages = [
        { value: 'English', label: t('English') },
        { value: 'Ukrainian', label: t('ukrainian') },
        { value: 'French', label: t('French') },
    ]

    return (
        <Modal centered show={show} onHide={handleClose} size="lg">
            <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton className="border-0">
                    <Modal.Title className="fs-5">{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="px-6">
                        {/* Menu Name */}
                        <Form.Group controlId="menuName" className="mb-4">
                            <Row className="align-items-center">
                                <Col sm={3}>
                                    <Form.Label htmlFor="createMenu-name-field" className="fs-7 fw-bold text-start mb-0">{t('Menu name')}
                                        <span className="text-danger ms-1">*</span>
                                    </Form.Label>
                                </Col>
                                <Col sm={9}>
                                    <Form.Control
                                        id="createMenu-name-field"
                                        type="text"
                                        value={menuName}
                                        onChange={(e) => setMenuName(e.target.value)}
                                        required
                                        maxLength={50}
                                        placeholder={t('Add name')}
                                        disabled={mode === 'restore'}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>
                        {/* Menu Type */}
                        <Form.Group controlId="menuType" className="mb-4">
                            <Row className="align-items-center">
                                <Col sm={3}>
                                    <Form.Label htmlFor="createMenu-type-field" className="fs-7 fw-bold text-start mb-0">
                                        {t('Menu type')}
                                        <span className="text-danger ms-1">*</span>
                                    </Form.Label>
                                </Col>
                                <Col sm={9}>
                                    <ReactSelect
                                        id="createMenu-type-field"
                                        value={optionsTypeMenu.find(option => option.value === menuType) || null}
                                        options={optionsTypeMenu}
                                        placeholder={t("Type of menu")}
                                        onChange={(option) => setMenuType(String(option.value))}
                                        isSearchable={true}
                                        isTooltip={true}
                                        disabled={mode === 'edit' || mode === 'restore'}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>
                        {/* Language Versions */}
                        <Form.Group controlId="languages" className="mb-4">
                            <Row className="align-items-center">
                                <Col sm={3}>
                                    <Form.Label htmlFor="createMenu-language-field" className="fs-7 fw-bold text-start mb-0">
                                        {t('Language Versions')}
                                        <span className="text-danger ms-1">*</span>
                                    </Form.Label>
                                </Col>
                                <Col sm={9}>
                                    <div className="position-relative">
                                        <ReactSelect
                                            id="createMenu-language-field"
                                            value={optionsLanguages.find(option => option.value === languages) || null}
                                            options={[]}
                                            placeholder={t("Select language versions of the menu")}
                                            onChange={(e) =>
                                                    setLanguages(Array.from(e.target.selectedOptions, (option) => option.value))
                                                }
                                            isSearchable={true}
                                            required
                                            disabled={mode === 'restore'}
                                            isMulti={true}
                                        />
                                        <OverlayTrigger placement="left"
                                            overlay={<Tooltip>{t('The translation can be added during editing')}</Tooltip>}>
                                            <div className="position-absolute top-50 translate-middle-y end-0 me-4">
                                                <i className="bi bi-info-circle pe-2"></i>
                                            </div>
                                        </OverlayTrigger>
                                    </div>
                                </Col>
                            </Row>
                        </Form.Group>
                        {/* Main Language */}
                        <Form.Group controlId="mainLanguage" className="mb-4">
                            <Row className="align-items-center">
                                <Col sm={3}>
                                    <Form.Label htmlFor="createMenu-languageMain-field" className="fs-7 fw-bold text-start mb-0">
                                        {t('Main Language')} <span className="text-danger">*</span>
                                    </Form.Label>
                                </Col>
                                <Col sm={9}>
                                    <div className="position-relative">
                                        <Form.Control type="text" value={mainLanguage} disabled />
                                        <OverlayTrigger placement="left"
                                            overlay={<Tooltip>{t('The main language can be edited in the company settings')}</Tooltip>}>
                                            <div className="position-absolute top-50 translate-middle-y end-0 me-4">
                                                <i className="bi bi-info-circle pe-2"></i>
                                            </div>
                                        </OverlayTrigger>
                                    </div>
                                </Col>
                            </Row>
                        </Form.Group>
                    </div>
                </Modal.Body>
                <Modal.Footer className="border-top-0">
                    <Button variant="outline-primary" className="custom-btn" onClick={handleClose}>
                        {t('Cancel')}
                    </Button>
                    <Button variant="primary" className="custom-btn fw-bold" type="submit">
                        {saveButtonText}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default AddEditMenuModal;