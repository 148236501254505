import React from 'react';
import { Modal, Button, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface Category {
    id: number;
    name: string;
    itemsAttached: boolean;
    subcategories: Category[];
    subcategoriesHaveItems: boolean;
}

interface DeleteCategoryModalProps {
    show: boolean;
    handleClose: () => void;
    category: Category;
    onDelete: () => void;
}

const DeleteCategoryModal: React.FC<DeleteCategoryModalProps> = ({
                                                                     show,
                                                                     handleClose,
                                                                     category,
                                                                     onDelete,
                                                                 }) => {
    const { t } = useTranslation();

    // Determine which variant to display
    const getVariant = () => {
        if (category.subcategories.length === 0) {
            // Category has no subcategories
            if (!category.itemsAttached) {
                // Variant 1: No items attached
                return 'variant1';
            } else {
                // Variant 2: Items attached
                return 'variant2';
            }
        } else {
            // Category has subcategories
            if (!category.itemsAttached) {
                if (category.subcategoriesHaveItems) {
                    // Variant 4: Subcategories have items attached
                    return 'variant4';
                } else {
                    // Variant 3: Subcategories have no items attached
                    return 'variant3';
                }
            } else {
                // Variant 2: Category has items attached
                return 'variant2';
            }
        }
    };

    const variant = getVariant();

    // Modal content based on variant
    const renderModalContent = () => {
        switch (variant) {
            case 'variant1':
                return (
                    <>
                        <Modal.Body className="border-bottom-0">
                            <Col xs={12} md={10} className="mx-md-auto text-center">
                                {t('Do you really want to delete this category?')}
                            </Col>
                        </Modal.Body>
                        <Modal.Footer className="border-top-0">
                            <Col>
                                <Button variant="outline-primary" className="w-100" onClick={onDelete}>
                                    {t('Yes')}
                                </Button>
                            </Col>
                            <Col>
                                <Button variant="primary" className="w-100 fw-bold" onClick={handleClose}>
                                    {t('Cancel')}
                                </Button>
                            </Col>
                        </Modal.Footer>
                    </>
                );
            case 'variant2':
                return (
                    <>
                        <Modal.Body className="border-bottom-0">
                            <Col xs={12} md={10} className="mx-md-auto text-center">
                                {t('This category is associated with dishes. Please edit the dishes linked to this category before deletion.')}
                            </Col>
                        </Modal.Body>
                        <Modal.Footer className="border-top-0">
                            <Button variant="primary" className="w-100" onClick={handleClose}>
                                {t('Ok')}
                            </Button>
                        </Modal.Footer>
                    </>
                );
            case 'variant3':
                return (
                    <>
                        <Modal.Body className="border-bottom-0">
                            <Col xs={12} md={10} className="mx-md-auto text-center">
                                {t('This category has subcategories which will also be deleted. Please move them if you wish to keep them.')}
                            </Col>
                        </Modal.Body>
                        <Modal.Footer className="border-top-0">
                            <Col>
                                <Button variant="outline-primary" className="w-100" onClick={handleClose}>
                                    {t('Cancel')}
                                </Button>
                            </Col>
                            <Col>
                                <Button variant="primary" className="w-100 fw-bold" onClick={onDelete}>
                                    {t('Delete Category')}
                                </Button>
                            </Col>
                        </Modal.Footer>
                    </>
                );
            case 'variant4':
                return (
                    <>
                        <Modal.Body className="border-bottom-0">
                            <Col xs={12} md={10} className="mx-md-auto text-center">
                                {t('This category has subcategories that are linked to dishes. Move the subcategories linked to dishes to delete the category.')}
                            </Col>
                        </Modal.Body>
                        <Modal.Footer className="border-top-0">
                            <Button variant="primary" className="w-100 fw-bold" onClick={handleClose}>
                                {t('Ok')}
                            </Button>
                        </Modal.Footer>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <Modal centered show={show} onHide={handleClose}>
            <Modal.Header closeButton className="border-bottom-0">
                <Modal.Title className="fs-5">{t('Delete Category')} {category.name}</Modal.Title>
            </Modal.Header>
            {renderModalContent()}
        </Modal>
    );
};

export default DeleteCategoryModal;
