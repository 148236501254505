import React, {useEffect, useRef, useState} from 'react';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {Button, Col, FormCheck, Row, CloseButton, Badge, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {
    useCreateMenuCategoryMutation,
    useCreateMenuItemMutation, useGetCurrentMenuItemQuery,
    useGetMenuAllergensQuery, useGetMenuCategoriesQuery,
    useGetMenuMarkersQuery,
    useGetMenuTypesQuery,
    useGetMenuUnitsQuery
} from '@me-team/host/src/modules/menu/graphql/menu.hooks';
import ImageCrop from '@services/ui-components/src/ImageCrop/ImageCrop';
import {ReactSelect, TableHeader, SpinnerLoader, Spinner} from "@services/ui-components";
import ModalCloneMenuItem from '../MenuItems/ModalCloneMenuItem';
import PriceInput from '../MenuItems/inputs/PriceInput';
import {IPickedCategoryNames} from '../MenuItems/constants';
import {ApolloError, useReactiveVar} from '@apollo/client';
import {currentCompanyIdVar} from '@me-team/host/src/apollo/globalVar/state';
import {CategoryInterface, CreateMeMenuCategoryVariables} from '../CreateMenuItem/types';
import {normalizeCategoryData} from '../../services/servicesMeMenuCategories/normalizeCategoryData';
import CategoryMeMenu from '../../services/servicesMeMenuCategories/CategoryMeMenu';
import RequestInputService from '../../services/servicesMeMenuCategories/RequestInputService';
import ErrorService from '../../services/ErrorService';
import {MenuCategoryProxy} from '@me-team/host/graphql/types';
import {ServiceModalWrapper} from '@me-pos/service-modal';
import i18next from 'i18next';
import {menuItemValidationSchema} from '../CreateMenuItem/constants';

interface IMenuItem {
    companyId: number | null
    name: string
    types: any[]
    categories: any[]
    description: string
    composition: string
    unit: number | null
    portions: any[]
    cookingTime: string
    isLongCookingTime: boolean
    markers: any[]
    allergens: any[]
    supplements: any[]
    favicon: string
    isUpdateImage: boolean
}

interface SelectOption {
    label: string;
    value: string;
}

interface CookingTimeRange {
    value: string;
    label: string;
}

const CurrentMenuItem = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { refetchMenuItems } = location.state || {};
    const { id } = useParams();
    const [descriptionLength, setDescriptionLength] = useState(0);
    const [compositionLength, setCompositionLength] = useState(0);
    const [showCloneModal, setShowCloneModal] = useState<boolean>(false);
    const [currentDishId, setCurrentDishId] = useState<number>(null);
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);
    const buttonCategoryRef = useRef<HTMLButtonElement>(null);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
    const [isMeMenuCategoryModalOpen, setIsMeMenuCategoryModalOpen] = useState<boolean>(false);
    const [currentCategory, setCurrentCategory] = useState(null);
    const [categoryList, setCategoryList] = useState<CategoryInterface[]>(null)
    const [categoryInput, setCategoryInput] = useState(null);
    const [pickedCategoryIds, setPickedCategoryIds] = useState<number[]>([]);
    const [pickedCategoryNames, setPickedCategoryNames] = useState<IPickedCategoryNames[]>([]);

    const [createMenuCategory] = useCreateMenuCategoryMutation()

    const {
        data: meMenucategoriesData,
        refetch: refetchCategories,
        loading: meMenuCategoryLoading
    } = useGetMenuCategoriesQuery({
        variables: {
            itemsPerPage: 1000,
            isDeleted: false
        },
        context: {
            errorType: 'local'
        },
        onCompleted: (data) => {
            const normalizedItems = normalizeCategoryData(data);
            setCategoryList(CategoryMeMenu.categoryModuleCreator(normalizedItems, null, null))
            setCategoryInput(RequestInputService.createServiceCategoryInput(null, null, normalizedItems));
        },
        onError: (error: ApolloError) => {
            setIsErrorModalOpen(ErrorService.errorHandling(error))
        },
    });
    const categoryListData: MenuCategoryProxy[] = meMenucategoriesData?.user?.company?.meMenuCategoriesPaginated?.categories
    const handleSubmitCreateCategory = async (variables: CreateMeMenuCategoryVariables) => {
        try {
            await createMenuCategory({
                variables,
                context: {
                    errorType: 'local'
                },
                onCompleted: (data) => {
                    data && setCurrentCategory(data.createMenuCategory);
                },
                onError: (error) => {
                    setIsErrorModalOpen(ErrorService.errorHandling(error))
                },
            });
        } catch (error) {
            console.error(error);
        }
    };

    const { data: menuAllergensData } = useGetMenuAllergensQuery();
    const { data: menuMarkersData } = useGetMenuMarkersQuery();
    const { data: menuTypesData } = useGetMenuTypesQuery();
    const { data: menuUnitsData } = useGetMenuUnitsQuery();
    const { data: dataGetCurrentMenu, loading } = useGetCurrentMenuItemQuery({
        variables: { id: +id },
    });

    const [createMenuItem] = useCreateMenuItemMutation();

    const cookingTimeOptions: CookingTimeRange[] = [
        {
            value: "5-10",
            label: `5-10 ${t("min")}`,
        },
        {
            value: "10-20",
            label: `10-20 ${t("min")}`,
        },
        {
            value: "20-30",
            label: `20-30 ${t("min")}`,
        },
        {
            value: "30-45",
            label: `30-45 ${t("min")}`,
        },
        {
            value: "45-60",
            label: `45-60 ${t("min")}`,
        },
        {
            value: "1hour",
            label: t("more one hour"),
        },

    ];

    // Category/Subcategory Delete Handler
    const handleRemoveCategory = (categoryId: number) => {
        setPickedCategoryIds((prev: number[]) => prev.filter((id) => id !== categoryId));
        setPickedCategoryNames((prev) => prev.filter((cat) => cat.id !== categoryId));
    };

    const handleLabelClick = (event: React.MouseEvent<HTMLLabelElement>) => {
        event.preventDefault();
        if (buttonCategoryRef.current) {
            buttonCategoryRef.current.focus();
        }
    };

    const handleCloneClick = (id: number) => {
        setCurrentDishId(id)
        setShowCloneModal(true);
    };

    const menuUnitsOptions =
        menuUnitsData?.relations?.units?.map((unit) => ({
            label: unit.name,
            value: unit.id,
        })) || [];

    const [initialValues, setInitialValues] = useState<IMenuItem>({
        name: '',
        types: [],
        description: '',
        composition: '',
        categories: [],
        unit: null,
        portions: [{ name: '', volume: '', price: '' }],
        cookingTime: '',
        isLongCookingTime: false,
        markers: [],
        allergens: [],
        companyId: 459,
        supplements: [],
        favicon: '',
        isUpdateImage: false,
    });

    useEffect(() => {
        if (dataGetCurrentMenu) {
            const menuItem = dataGetCurrentMenu.user.company.menuItems.items[0];
            setInitialValues((prevValues) => ({
                ...prevValues,
                name: menuItem.name,
                types: menuItem.types.map((type: any) => type.id),
                description: menuItem.description,
                composition: menuItem.composition,
                categories: menuItem.categories.map((category: any) => category.id),
                unit: menuItem.unit?.id,
                portions: menuItem.portions.map((portion: any) => ({
                    name: portion.name,
                    volume: portion.volume,
                    price: portion.price,
                })),
                cookingTime: menuItem.cookingTime,
                isLongCookingTime: menuItem.isLongCookingTime,
                markers: menuItem.markers.map((marker: any) => marker.id),
                allergens: menuItem.allergens.map((allergen: any) => allergen.id),
                favicon: menuItem.favicon || '',
                isUpdateImage: false,
            }));
            const initialCategoryNames: IPickedCategoryNames[] = menuItem?.categories?.map((category) => ({
                id: category.id,
                name: category.name,
                parentName: category.parentCategory ? category.parentCategory.name : null,
            }))
            setPickedCategoryNames(initialCategoryNames)
        }
    }, [dataGetCurrentMenu]);

    const handleSubmit = async (values: any, { setSubmitting }: any) => {
        const input = {
            companyId: 459,
            name: values.name,
            types: values.types,
            categories: values.categories,
            unit: Number(values.unit),
            portions: values.portions,
            description: values.description,
            composition: values.composition,
            cookingTime: values.cookingTime,
            isLongCookingTime: values.isLongCookingTime,
            markers: values.markers,
            allergens: values.allergens,
            favicon: values.favicon,
            isUpdateImage: values.isUpdateImage,
        };

        try {
            await createMenuItem({ variables: input });
            navigate('/branches');
            setSubmitting(false);
        } catch (error) {
            console.error('Error creating menu item:', error);
            setSubmitting(false);
        }
    };

    if (loading) return <Spinner/>;

    return (
        <>
            <TableHeader
                title={t("Edit dish")}
                paths={[{ path: "/branches", label: t("Branches") }, { path: "/branch/menu/items", label: t("List of dishes") }, { path: `/branch/menu/item/${id}`, label: t("Edit dish") }]}
                buttons={
                    <Row className='gx-3 justify-content-end'>
                        <Col sm={5} className="mb-2 mb-xxl-0">
                            <div className="position-relative">
                                <span className="position-absolute start-0 top-0 z-1 ps-2 pt-1 fs-8 text-grey">{t("Language version")}</span>
                                <ReactSelect
                                    id="listOfDishes-language-field"
                                    value={{value: 'Українська (основна)', label: 'Українська (основна)'}}
                                    options={[]}
                                    placeholder={t("Type of branch")}
                                    onChange={() => {}}
                                    isSearchable={true}
                                    disabled={false}
                                    isMeMenu={true}
                                    isTitle={true}
                                />
                            </div>
                        </Col>
                        <Col sm={4}>
                            <Button
                              variant="outline-primary"
                              className="w-100"
                              onClick={() => handleCloneClick(+id)}
                            >
                                {t("Clone dish")}
                            </Button>
                        </Col>
                    </Row>
                }
                colSize={6}
                btnColSize={6}
            />
            <Formik
                initialValues={initialValues}
                validationSchema={menuItemValidationSchema(t)}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({
                      setFieldValue,
                      isSubmitting,
                      values,
                      errors,
                      touched
                }) => {
                    const handlePhoto = (croppedImage: string) => {
                        setFieldValue('favicon', croppedImage);
                    };

                    useEffect(() => {
                        pickedCategoryIds && setFieldValue('categories', pickedCategoryIds);
                    }, [pickedCategoryIds])
                    return (
                        <Form>
                            <Row className="mt-6">
                                <Col lg={9} className="pe-lg-5">
                                    <h5 className="fs-24 text-capitalize mb-3 mb-lg-4">{t("Basic information")}</h5>
                                    <div className="pe-lg-4">
                                        <div className="mb-3">
                                            <Row className="align-items-center">
                                                <Col xs={3} md={2}>
                                                    <label htmlFor="editDish-name-field" className="fs-7 fw-bold">{t('Dish name')}
                                                        <span className="text-danger ms-1">*</span>
                                                    </label>
                                                </Col>
                                                <Col xs={9} md={10}>
                                                    <Field
                                                      id="editDish-name-field"
                                                      name="name"
                                                      placeholder={t('Add name')}
                                                      maxLength={100}
                                                      className="form-control"
                                                    />
                                                </Col>
                                            </Row>
                                            <Col xs={9} md={10} className="ms-auto">
                                                <ErrorMessage name="name">{msg => <p className="fs-7 py-1 px-2 text-danger m-0">{msg}</p>}</ErrorMessage>
                                            </Col>
                                        </div>
                                        <Row className="mb-3 py-3">
                                            <Col xs={3} md={2}>
                                                <label htmlFor="editDish-type-field" className="fs-7 fw-bold">{t('Type menu')}
                                                    <span className="text-danger ms-1">*</span>
                                                </label>
                                            </Col>
                                            <Col xs={9} md={10}>
                                                <div className="d-flex justify-content-start flex-wrap gap-3">
                                                    {menuTypesData?.menuTypes?.map((type) => (
                                                        <div key={type.id} className="mb-1 form-check form-switch ps-3">
                                                            <Field name="types">
                                                                {({field}: any) => (
                                                                    <FormCheck
                                                                        type="checkbox"
                                                                        id={`switch-${type.id}`}
                                                                        className="fs-7 text-primary-grey"
                                                                        label={type.name}
                                                                        checked={field.value.includes(type.id)}
                                                                        onChange={() => {
                                                                            const nextValue = field.value.includes(type.id)
                                                                                ? field.value.filter((id: number) => id !== type.id)
                                                                                : [...field.value, type.id];
                                                                            setFieldValue('types', nextValue);
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                        </div>
                                                    ))}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col
                                              md={2}
                                              className='mb-2 mb-md-0'>
                                                <label
                                                  htmlFor='addDish-category-field'
                                                  className='text-dark fs-7 fw-bold py-1'
                                                  onClick={handleLabelClick}
                                                >
                                                    {t('Category')}&nbsp;<span className='text-danger'>*</span>
                                                </label>
                                            </Col>
                                            <Col md={10}>
                                                <div className='d-flex'>
                                                    {
                                                        pickedCategoryNames?.length > 0 ?
                                                            <div className='d-flex flex-wrap gap-3 me-4'>
                                                                  {pickedCategoryNames.map((category: IPickedCategoryNames) => (
                                                                        <Badge
                                                                              bg="additional-grey"
                                                                              className="rounded-1 p-2 d-flex align-items-center"
                                                                            >
                                                                              <span
                                                                                className='fs-16 mb-2 me-2 mb-lg-0 text-dark'
                                                                              >
                                                                                {category.name}
                                                                                  {category.parentName ?
                                                                                    <>
                                                                                        <i
                                                                                          className="bi bi-arrow-right mx-1 "></i> {category.parentName}
                                                                                    </>
                                                                                    : null}
                                                                              </span>
                                                                                <CloseButton
                                                                                  variant='dark'
                                                                                  className="fs-7 text-dark p-0"
                                                                                  onClick={() => handleRemoveCategory(category.id)}
                                                                                />
                                                                        </Badge>
                                                                  ))}
                                                            </div>
                                                        : null
                                                    }
                                                    <div>
                                                        <Button
                                                          ref={buttonCategoryRef}
                                                          variant={`${touched.categories && errors.categories ? 'outline-danger' : 'outline-primary'}`}
                                                          className='fw-normal py-1'
                                                          id='addDish-categories-field'
                                                          style={{width: '149px', maxHeight: '31px'}}
                                                          onClick={() => {
                                                              setIsMeMenuCategoryModalOpen(true)
                                                          }}
                                                        >
                                                            {pickedCategoryNames?.length > 0 ? t('Change Category') : t('Select Category')}
                                                        </Button>
                                                        <ErrorMessage name="categories">{msg => <div
                                                          className='text-danger d-block fs-7 mt-1'>{msg}</div>}
                                                        </ErrorMessage>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col xs={3} md={2}>
                                                <label htmlFor="editDish-description-field" className="fs-7 fw-bold pt-2">{t("Description dish")}</label>
                                            </Col>
                                            <Col xs={9} md={10}>
                                                <Field
                                                    as="textarea"
                                                    id="editDish-description-field"
                                                    name="description"
                                                    placeholder={t("Add description")}
                                                    className="form-control"
                                                    onChange={(e: any) => {
                                                        setDescriptionLength(e.target.value.length);
                                                        setFieldValue('description', e.target.value);
                                                    }}
                                                    maxLength={400}
                                                />
                                                <ErrorMessage name="description">{(msg) => <div
                                                    className="fs-7 p-1 text-danger m-0">{msg}</div>}</ErrorMessage>
                                                <div className="fs-7 text-grey text-start mt-1 px-1">
                                                    {`${descriptionLength}/400`}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col xs={3} md={2}>
                                                <label htmlFor="editDish-composition-field" className="fs-7 fw-bold text-capitalize text-break pt-2">{t("Short composition")}</label>
                                            </Col>
                                            <Col xs={9} md={10}>
                                                <Field
                                                    as="textarea"
                                                    id="editDish-composition-field"
                                                    name="composition"
                                                    placeholder={t("Add short composition")}
                                                    className="form-control"
                                                    onChange={(e: any) => {
                                                        setCompositionLength(e.target.value.length);
                                                        setFieldValue('composition', e.target.value);
                                                    }}
                                                />
                                                <ErrorMessage name="composition">{(msg) => <div
                                                    className="fs-7 p-1 text-danger m-0">{msg}</div>}</ErrorMessage>
                                                <div className="fs-7 text-grey text-start mt-1 px-1">
                                                    {`${compositionLength}/400`}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col xs={3} md={2}>
                                                <label htmlFor="editDish-measurement-field" className="fs-7 fw-bold text-break">{t('Units of measurement')}
                                                    <span className="text-danger ms-1">*</span>
                                                </label>
                                            </Col>
                                            <Col xs={9} md={10}>
                                                <ReactSelect
                                                    id="editDish-measurement-field"
                                                    // className="select-city"
                                                    // classNamePrefix="select"
                                                    options={menuUnitsOptions}
                                                    value={menuUnitsOptions.find((option: any) => option.value === values.unit)}
                                                    onChange={(selectedOption: SelectOption | null) => {
                                                        setFieldValue('unit', selectedOption ? selectedOption.value : '');
                                                    }}
                                                    placeholder={t('Select Unit of Measurement')}
                                                    // isClearable
                                                />
                                                <ErrorMessage name="unit" component="div" className="invalid-feedback"/>
                                            </Col>
                                        </Row>
                                    </div>

                                    <FieldArray
                                        name="portions"
                                        render={({remove, push}) => (
                                            <div className="my-4 my-lg-5 pe-lg-4">
                                                <h5 className="fs-24 mb-3 mb-lg-4">{t('Servings')}</h5>
                                                {values.portions.map((portion: string, index: number) => (
                                                    <Row className="mb-3">
                                                        <Col xs={3} md={2}>
                                                            <label htmlFor="editDish-servings-field" className="fs-7 fw-bold">{t('Serving name')}
                                                                <span className="text-danger ms-1">*</span>
                                                            </label>
                                                        </Col>
                                                        <Col xs={9} md={10}>
                                                            <Row key={index}>
                                                                <Col  xs={12} xxl className="mb-3 mb-xxl-0">
                                                                    <Field name={`portions.${index}.name`}
                                                                           placeholder={t('Serving name')}
                                                                           className="form-control"/>
                                                                    <ErrorMessage name={`portions.${index}.name`}
                                                                                  component="div"
                                                                                  className="text-danger"/>
                                                                </Col>
                                                                <Col md={6} xxl={4} className="mb-3 mb-md-0">
                                                                    <Row className="gx-2">
                                                                        <Col xs={5}>
                                                                            <label htmlFor="editDish-weightVolume-field" className="fs-7 fw-bold">{t("Weight/ Volume dish")}
                                                                                <span className="text-danger ms-1">*</span>
                                                                            </label>
                                                                        </Col>
                                                                        <Col xs={7}>
                                                                            <Field name={`portions.${index}.volume`}
                                                                                   placholder={t("Weight/Volume")}
                                                                                   id="editDish-weightVolume-field"
                                                                                   className="form-control"
                                                                                   type="number"/>
                                                                            <ErrorMessage name={`portions.${index}.volume`}
                                                                                          component="div"
                                                                                          className="text-danger"/>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                                <Col md={6} xxl={4}>
                                                                    <Row className="gx-2 align-items-center">
                                                                        <Col xs={5} lg={4}>
                                                                            <label htmlFor="editDish-price-field" className="fs-7 fw-bold">{t("Price")}
                                                                                <span className="text-danger ms-1">*</span>
                                                                            </label>
                                                                        </Col>
                                                                        <Col xs={7} lg={8} className="d-flex">
                                                                            <PriceInput
                                                                              name={`portions.${index}.price`}
                                                                              placeholder='0.00'
                                                                              id="editDish-price-field"
                                                                            />
                                                                            {values.portions.length > 1 && index >= 1 && (
                                                                                <div className="ms-2">
                                                                                    <Button
                                                                                        type="button"
                                                                                        variant="outline-secondary"
                                                                                        className="w-100 px-3 py-6 fs-normal rounded-1 align-self-start"
                                                                                        onClick={() => remove(index)}
                                                                                    >
                                                                                        <i className="bi bi-trash3 text-dark"></i>
                                                                                    </Button>
                                                                                </div>

                                                                            )}
                                                                            <ErrorMessage name={`portions.${index}.price`}
                                                                                          component="div"
                                                                                          className="text-danger"/>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                                {index === values.portions.length - 1 && (
                                                                    <Col xs={12} className="mt-3">
                                                                        <Button type="button" variant="light"
                                                                           className="fs-7 text-primary p-0 border-0 bg-transparent"
                                                                                onClick={() => push({
                                                                                    name: '',
                                                                                    volume: '',
                                                                                    price: ''
                                                                                })}>
                                                                            <i className="bi bi-plus me-1"></i>
                                                                            {t("Add another portion option")}
                                                                        </Button>
                                                                    </Col>
                                                                )}
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                ))}
                                            </div>
                                        )}
                                    />

                                    <h5 className="fs-24 mb-3 mb-lg-4">{t('Additional Information')}</h5>
                                    <Row className="mb-3">
                                        <Col xs={3} md={2}>
                                            <label htmlFor="editDish-cookingTime-field" className="fs-7 fw-bold text-break">{t('Cooking time')}</label>
                                        </Col>
                                        <Col xs={9} md={10}>
                                            <ReactSelect
                                                id="editDish-cookingTime-field"
                                                className="select-city"
                                                classNamePrefix="select"
                                                options={cookingTimeOptions}
                                                value={cookingTimeOptions.find((option: any) => option.value === values.cookingTime)}
                                                onChange={(selectedOption: SelectOption | null) => {
                                                    setFieldValue('cookingTime', selectedOption ? selectedOption.value : '');
                                                }}
                                                placeholder={t('Select cooking time')}
                                                isClearable
                                            />
                                            <ErrorMessage name="unit" component="div" className="invalid-feedback"/>
                                            <div className="form-check mt-2 mb-0">
                                                <Field
                                                    type="checkbox"
                                                    name="isLongCookingTime"
                                                    id="isLongCookingTime"
                                                    className="form-check-input"
                                                    checked={values.isLongCookingTime}
                                                />
                                                <label className="form-check-label fs-7" htmlFor="isLongCookingTime">
                                                    {t('Display icon "long cooking"')}
                                                </label>
                                                <OverlayTrigger
                                                    overlay={<Tooltip>{t('There will be a marker next to this dish in the menu')}</Tooltip>}>
                                                    <i className="bi bi-info-circle ms-1 fs-7 pt-1"></i>
                                                </OverlayTrigger>
                                                <ErrorMessage name="isLongCookingTime" component="div" className="text-danger"/>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className="mb-3">
                                        <Col xs={3} md={2}>
                                            <label htmlFor="editDish-warning-field" className="fs-7 fw-bold">{t('Warning')}</label>
                                        </Col>
                                        <Col xs={9} md={10}>
                                            <div className="d-flex justify-content-start align-content-center flex-wrap border rounded-1 py-6 gap-3">
                                                {menuMarkersData?.menuMarkers?.map((marker) => (
                                                    <div key={marker.id} className="form-check form-switch">
                                                        <Field name="markers">
                                                            {({field}: any) => (
                                                                <FormCheck
                                                                    type="checkbox"
                                                                    id={`switch-${marker.id}`}
                                                                    className="fs-7 text-primary-grey ps-2 mb-0"
                                                                    label={marker.name}
                                                                    checked={field.value.includes(marker.id)}
                                                                    onChange={() => {
                                                                        const nextValue = field.value.includes(marker.id)
                                                                            ? field.value.filter((id: number) => id !== marker.id)
                                                                            : [...field.value, marker.id];
                                                                        setFieldValue('markers', nextValue);
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                ))}
                                            </div>
                                            <small className="text-grey">{t('Optional small icons placed next to the item title')}</small>
                                        </Col>
                                    </Row>

                                    <Row className="mb-3">
                                        <Col xs={3} md={2}>
                                            <label htmlFor="editDish-allergen-field" className="fs-7 fw-bold">{t('Allergen list')}</label>
                                        </Col>
                                        <Col xs={9} md={10}>
                                            <div className="d-flex justify-content-start flex-wrap border rounded-1 py-6 gap-3">
                                                {menuAllergensData?.menuAllergens?.map((allergen, index) => (
                                                    <div key={allergen.id} className="form-check form-switch">
                                                        <Field name="allergens">
                                                            {({field}: any) => (
                                                                <FormCheck
                                                                    type="checkbox"
                                                                    id={`switch-${allergen.id}`}
                                                                    label={<><span className="fs-7 text-primary-grey me-1">{index + 1}</span>
                                                                        ({allergen.name})</>}
                                                                    className="fs-7 text-grey ps-2 mb-0"
                                                                    checked={field.value.includes(allergen.id)}
                                                                    onChange={() => {
                                                                        const nextValue = field.value.includes(allergen.id)
                                                                            ? field.value.filter((id: number) => id !== allergen.id)
                                                                            : [...field.value, allergen.id];
                                                                        setFieldValue('allergens', nextValue);
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                ))}
                                            </div>
                                            <small className="text-grey">{t('Small numbers placed next to the item title. Food ingredients that must be declared as allergens in the EU')}</small>
                                        </Col>
                                    </Row>

                                    <Row className="mb-4 mb-lg-5">
                                        <Col xs={3} md={2}>
                                            <label htmlFor="editDish-options-field" className="fs-7 fw-bold text-break">{t('List of options/additives')}</label>
                                        </Col>
                                        <Col xs={9} md={10}>
                                            <div className="d-flex align-items-center w-100 mb-3">
                                                <div className="w-100">
                                                    <ReactSelect
                                                        id="editDish-options-field"
                                                        components={{DropdownIndicator: () => null}}
                                                        options={[]}
                                                        value={[{
                                                            label: "123",
                                                            value: "1"
                                                        }]}
                                                        placeholder={t('Cписок опцій/добавок')}
                                                        isClearable={false}
                                                        closeMenuOnSelect={false}
                                                        openMenuOnClick={false}
                                                        isMulti
                                                    />
                                                </div>
                                                <Button onClick={() => setShowSupplementMenu(true)} type="button"
                                                        variant="outline-secondary"
                                                        className="h-100 ms-3 px-3 py-6 fs-normal rounded-1 align-self-start">
                                                    <i className="bi bi-plus-lg text-dark"></i>
                                                </Button>
                                                <ErrorMessage name="unit" component="div" className="invalid-feedback"/>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={3} className="ms-auto ps-lg-0">
                                    <h5 className="fs-24 mb-4">{t('Photo dish')}</h5>
                                    <ImageCrop
                                        key={values.favicon}
                                        previewPhoto={values.favicon}
                                        setFieldValue={setFieldValue}
                                        fieldSetter={handlePhoto}
                                        fieldName="favicon"
                                        modalTitle={t('Dish logo')}
                                        dismissImage={() => setFieldValue('favicon', null)}
                                        notBGinfo
                                        isMenuInfo
                                    />
                                </Col>
                            </Row>
                            <div className="d-sm-flex my-4 mt-lg-0">
                                <Button id={"addDish-cancel-button"} onClick={() => navigate('/branch/menu/items')} variant="outline-primary" className="fw-normal mb-3 mb-sm-0 me-3 custom-btn rounded-1">
                                    {t("Cancel")}
                                </Button>
                                <Button id={"addDish-submit-button"} type="submit" variant="primary" disabled={isSubmitting} className="fw-bold custom-btn rounded-1">
                                    {isSubmitting ? <SpinnerLoader variant="light" loadingText={t("Saving...")}/> : t('Save')}
                                </Button>
                            </div>
                        </Form>
                    )
                }}
            </Formik>

            {
                isMeMenuCategoryModalOpen && meMenucategoriesData && !meMenuCategoryLoading ?
                  <ServiceModalWrapper
                    i18n={i18next}
                    selectedCategoryId={null}
                    selectedSubcategoryId={null}
                    apolloUrl={process.env.REACT_APP_API_URL}
                    isOpen={isMeMenuCategoryModalOpen}
                    onClose={() => {
                        setIsMeMenuCategoryModalOpen(!isMeMenuCategoryModalOpen)
                    }}
                    onCategorySelect={(selectedCategory) => {
                        if (selectedCategory) {
                            setPickedCategoryIds((prev) => {
                                if (!prev.includes(selectedCategory.id)) {
                                    return [...prev, selectedCategory.id];
                                }
                                return prev;
                            });

                            setPickedCategoryNames((prev: any) => {
                                if (!prev.some((cat: any) => cat.id === selectedCategory.id)) {
                                    return [...prev, {
                                        id: selectedCategory.id,
                                        name: selectedCategory.name,
                                        parentName: null
                                    }];
                                }
                                return prev;
                            });
                        }
                        setIsMeMenuCategoryModalOpen(!isMeMenuCategoryModalOpen)
                    }}
                    onSubcategorySelect={(selectedSubcategory) => {
                        if (selectedSubcategory) {
                            setPickedCategoryIds((prev) => {
                                if (!prev.includes(selectedSubcategory.id)) {
                                    return [...prev, selectedSubcategory.id];
                                }
                                return prev;
                            });

                            setPickedCategoryNames((prev: any) => {
                                if (!prev.some((cat: any) => cat.id === selectedSubcategory.id)) {
                                    return [
                                        ...prev,
                                        {
                                            id: selectedSubcategory.id,
                                            name: selectedSubcategory.name,
                                            parentName: selectedSubcategory?.parentCategory?.name || null,
                                        },
                                    ];
                                }
                                return prev;
                            });
                        }
                        setIsMeMenuCategoryModalOpen(!isMeMenuCategoryModalOpen)
                    }}
                    categoryList={categoryList}
                    categoryInput={categoryInput}
                    categoryListData={categoryListData}
                    refetchCategories={refetchCategories}
                    onSubmitCreate={handleSubmitCreateCategory}
                    currentCategory={currentCategory}
                    categoryService={CategoryMeMenu}
                    requestInputService={RequestInputService}
                    normalizeCategoryData={normalizeCategoryData}
                  />
                  : null
            }

            <ModalCloneMenuItem
              showCloneModal={showCloneModal}
              setShowCloneModal={setShowCloneModal}
              currentDishId={currentDishId}
              refetchMenuItems={refetchMenuItems}
            />
        </>
    );
};

export default CurrentMenuItem;
