import React from 'react';
import { useTranslation } from 'react-i18next';
import {Button} from 'react-bootstrap';
import EmptyList from '../EmptyList/EmptyList';

interface EmptyListComponentProps {
    isFilterNotEmpty: boolean;
    buttonName: React.ReactNode;
    emptyFilterTitle: string;
    emptyListTitle: string;
    onClickButton?: () => void;
    isEmployeeRole?: boolean;
    imageSrc?: string;
    altText?: string;
}

const RenderEmptyList: React.FC<EmptyListComponentProps> = ({
                                                                isFilterNotEmpty,
                                                                emptyFilterTitle,
                                                                emptyListTitle,
                                                                buttonName,
                                                                onClickButton,
                                                                isEmployeeRole,
                                                                imageSrc,
                                                                altText,
                                                            }) => {
    const { t } = useTranslation();

    return (
        !isFilterNotEmpty ? (
            <EmptyList title={emptyListTitle} imageSrc={imageSrc} altText={altText}>
                <Button
                    variant='primary'
                    className={`w-100 my-2 mb-lg-0 ${isEmployeeRole ? 'd-none' : ''}`}
                    onClick={onClickButton}
                >
                    {buttonName}
                </Button>
            </EmptyList>
        ) : (
            emptyFilterTitle ? <EmptyList title={emptyFilterTitle}/> : null
        )
    );
};

export default RenderEmptyList;
