import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Button, Col, Modal, Row} from 'react-bootstrap';
import {Spinner} from '@services/ui-components';
import {ErrorMessage, Field, Form, Formik, FormikHelpers} from "formik";
import {
  useCloneMenuItemMutation,
  useGetCurrentMenuItemLazyQuery,
  useGetMenuCategoriesQuery, useGetMenuItemsQuery
} from '@me-team/host/src/modules/menu/graphql/menu.hooks';
import {normalizeCategoryData} from '../../services/servicesMeMenuCategories/normalizeCategoryData';
import {ApolloError} from '@apollo/client';
import ErrorService from '../../services/ErrorService';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import i18n from '@me-team/host/src/i18n';
import {CategoryPickerCategory, CategoryPickerComponent} from '@me-pos/category-picker';
import {CategoryInterface} from '../CreateMenuItem/types';
import transformCategories from '@me-team/host/src/utils/transformCategories';
import {useNavigate} from 'react-router-dom';
import {MenuItemPortionInput, MenuItemProxy} from '@me-team/host/graphql/types';
import {
  getCategoryName,
  getInitialDishName,
  getSubCategoryName,
  validationCloneDishSchema
} from './constants';

interface ModalCloneMenuItemProps {
  showCloneModal: boolean;
  setShowCloneModal: React.Dispatch<React.SetStateAction<boolean>>;
  currentDishId: number;
  refetchMenuItems?: () => void;
}

interface DataCloneForm {
  name: string;
  categories: number[];
}

const ModalCloneMenuItem: React.FC<ModalCloneMenuItemProps> = ({
                                                                 showCloneModal,
                                                                 setShowCloneModal,
                                                                 currentDishId,
                                                                 refetchMenuItems
                                                               }) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
  const dropdownRef = useRef(null);
  const [isPickerOpen, setIsPickerOpen] = useState(false);

  const [getCurrentMenuItem, {
    data: currentMenuItemData,
    loading: currentMenuItemLoading,
    refetch: currentMenuItemRefetch
  }] = useGetCurrentMenuItemLazyQuery();
  const [cloneMenuItemMutation] = useCloneMenuItemMutation();

  useEffect(() => {
    currentDishId && getCurrentMenuItem({variables: {id: currentDishId}});
  }, [currentDishId]);

  const menuItemData: MenuItemProxy = currentMenuItemData?.user?.company?.menuItems?.items[0]

  const categoryData = menuItemData?.categories[0]
  const startCategoryId = categoryData?.id
  const startCategoryName = getCategoryName(categoryData)
  const startSubCategoryName = getSubCategoryName(categoryData)
  const initialDishName = getInitialDishName(menuItemData?.name, t);

  const [dishName, setDishName] = useState<string>(initialDishName);
  const [selectedCategory, setSelectedCategory] = useState<CategoryInterface[]>(null);

  const [currentCatName, setCurrentCatName] = useState({
    categoryName: startCategoryName,
    subCategoryName: startSubCategoryName,
  });

  const [pickedCategoryId, setPickedCategoryId] = useState<number[] | null>([startCategoryId]);

  useEffect(() => {
    if (menuItemData?.name) {
      setDishName(getInitialDishName(menuItemData?.name, t))
    }
    if (startCategoryId) {
      setPickedCategoryId([startCategoryId])
      setCurrentCatName({
        categoryName: startCategoryName,
        subCategoryName: startSubCategoryName ? startSubCategoryName : '',
      })
    }
  }, [startCategoryId, startCategoryName, startSubCategoryName, menuItemData?.name]);

  const {
    data: meMenuCategoriesData,
    refetch: refetchCategories,
    loading: meMenuCategoryLoading
  } = useGetMenuCategoriesQuery({
    variables: {
      itemsPerPage: 1000,
      isDeleted: false
    },
    context: {
      errorType: 'local'
    },
    onCompleted: (data) => {
      const normalizedItems = normalizeCategoryData(data);
      setSelectedCategory(
        transformCategories(normalizedItems)
      );
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    },
  });

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsPickerOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleCategoryNameChange = (categoryName: string) => {
    setCurrentCatName({categoryName, subCategoryName: ''});
  };
  const handleSubCategoryNameChange = (subCategoryName: string, categoryName: string = '') => {
    setCurrentCatName({categoryName, subCategoryName});
  };

  const initialValues: DataCloneForm = {
    name: initialDishName,
    categories: pickedCategoryId ? pickedCategoryId : null,
  };

  // const { refetch: refetchItems } = useGetMenuItemsQuery({
  //   variables: {
  //     itemsPerPage:  20,
  //     page: 1,
  //     sortBy: null,
  //     order: null,
  //     search: null,
  //     menuCategoryId: null,
  //   },
  //   skip: true,
  // });

  const handleCloneDish = async (
    values: DataCloneForm,
    {setErrors}: FormikHelpers<DataCloneForm>
  ) => {
    if (!currentDishId) return;

    const input = {
      name: values?.name,
      categories: values?.categories,
      types: menuItemData?.types?.map((type: any) => parseInt(type.id)),
      unit: menuItemData?.unit?.id,
      portions: menuItemData?.portions?.map((portion: MenuItemPortionInput) => ({
        name: portion.name,
        price: portion.price,
        volume: portion.volume,
      })),
      description: menuItemData?.description,
      composition: menuItemData?.composition,
      cookingTime: menuItemData?.cookingTime,
      isLongCookingTime: menuItemData?.isLongCookingTime,
      markers: menuItemData?.markers?.map((marker: any) => parseInt(marker.id)),
      allergens: menuItemData?.allergens?.map((allergen: any) => parseInt(allergen.id)),
      // Include supplements if necessary
      // supplements: dishToClone.supplements.map((supplement: any) => parseInt(supplement.id)),
      // Disable availability for all menu types if applicable
      // availability: false,
    };

    await cloneMenuItemMutation({
      variables: {
        cloneMenuItemId: currentDishId,
        input: input,
      },
      context: {
        errorType: 'local'
      },
      onCompleted: (data) => {
        const newDishId = data?.cloneMenuItem?.id
        setShowCloneModal(false);
        currentMenuItemRefetch()
        refetchMenuItems && refetchMenuItems()
        navigate(`/branch/menu/item/${newDishId}`);
      },
      onError: (error: ApolloError) => {
        const validationErrors = error?.graphQLErrors[0]?.extensions?.category
        if (validationErrors === 'menu_item.already_exists') {
          setErrors({name: t('A dish with this name already exists.')});
        }
        setIsErrorModalOpen(ErrorService.errorHandling(error))
      },
    });
  };

  if (currentMenuItemLoading) return <Spinner/>

  return (
    <>
      <Modal centered show={showCloneModal} onHide={() => setShowCloneModal(false)}>
        <Modal.Header closeButton className="border-bottom-0">
          {/*<Modal.Title className="fs-5">{t("Cloning")} {props.dishToClone?.name}</Modal.Title>*/}
          {/* When main language will be added*/}
          {/*<div className="text-primary bg-violet-light rounded-1 fs-7 p-1 px-6 ms-3">Українська</div>*/}
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          validationSchema={validationCloneDishSchema(t)}
          onSubmit={handleCloneDish}
        >
          {({
              setFieldValue,
              touched,
              errors
            }) => {
            useEffect(() => {
              pickedCategoryId && setFieldValue('categories', pickedCategoryId);
            }, [pickedCategoryId]);
            return (
              <Form>
                <Modal.Body>
                  <div className="form-group px-6 mb-3">
                    <Row className="align-items-center">
                      <Col sm={3} className="fs-7 fw-bold text-start">
                        <label htmlFor="name">{t("Name dish")}
                          <span className="text-danger ms-1">*</span>
                        </label>
                      </Col>
                      <Col sm={9}>
                        <Field
                          type="text"
                          name="name"
                          className={`fs-7 form-control rounded-1 ${touched.name && errors.name ? 'is-invalid' : ''}`}
                          id="cloneDish-name-field"
                          value={dishName}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const value = e.target.value.replace(/Copy - /, '');
                            setDishName(`${value}`);
                            setFieldValue('name', value);
                          }}
                          maxLength={50}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={9} className="ms-auto">
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="fs-7 mt-1 text-danger"
                        />
                        <div className="fs-7 text-grey mt-1">
                          0/50
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group px-6">
                    <Row className="align-items-center">
                      <Col sm={3}>
                        <label htmlFor="cloneDish-category-field"
                               className="fs-7 fw-bold"
                        >
                          {t('Category')} <span className="text-danger ms-1">*</span>
                        </label>
                      </Col>
                      <Col sm={9}>
                        <div className='position-relative mb-3 mb-lg-0'>
                          <input
                            type='text'
                            name='categories'
                            id='cloneDish-category-field'
                            className='form-control px-4 ps-6 cursor-pointer'
                            placeholder={t('Category')}
                            value={
                              currentCatName?.categoryName || currentCatName?.subCategoryName
                                ? `${currentCatName?.categoryName}${currentCatName?.subCategoryName}`
                                : ''
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              setIsPickerOpen(!isPickerOpen);
                            }}
                            readOnly
                          />
                          <i
                            className='bi bi-chevron-down position-absolute top-50 end-0 translate-middle-y text-dark me-2 cursor-pointer'
                            onClick={(e) => {
                              e.preventDefault();
                              setIsPickerOpen(!isPickerOpen);
                            }}
                          ></i>
                          <div
                            ref={dropdownRef}
                            className='dropdown position-absolute opacity-1 z-2 top-10  start-0 end-0 mt-1 bg-white shadow'>
                            {meMenuCategoriesData && selectedCategory && (
                              <CategoryPickerComponent
                                isOpen={isPickerOpen}
                                locale={i18n.language}
                                onSearchChange={(searchVealue: string) => {
                                }}
                                onChangeCategory={(currentCategory: CategoryPickerCategory) => {
                                  if (currentCategory.isPicked) {
                                    handleCategoryNameChange(currentCategory?.name);
                                    setPickedCategoryId([currentCategory?.id]);
                                    setIsPickerOpen(false);
                                  } else {
                                    setCurrentCatName({
                                      categoryName: '',
                                      subCategoryName: ''
                                    });
                                  }
                                }}
                                onChangeSubcategory={(currentSubcategory, currentCategory) => {
                                  if (currentSubcategory?.isPicked) {
                                    handleSubCategoryNameChange(currentSubcategory?.name);
                                    setPickedCategoryId([currentSubcategory?.id]);
                                    setIsPickerOpen(false);
                                  } else {
                                    setCurrentCatName({
                                      categoryName: '',
                                      subCategoryName: ''
                                    });
                                  }
                                }}
                                serviceCategories={selectedCategory}
                              />
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Col sm={8} className="ms-auto">
                      <ErrorMessage name="category">{msg => <p
                        className="fs-7 p-1 text-danger m-0">{msg}</p>}</ErrorMessage>
                    </Col>
                  </div>
                </Modal.Body>
                <Modal.Footer className="justify-content-between border-top-0">
                  <Button variant="outline-primary" className="custom-btn"
                          onClick={() => setShowCloneModal(false)}>
                    {t("cancel")}
                  </Button>
                  <Button
                    variant="primary"
                    className="custom-btn fw-bold"
                    type="submit"
                  >
                    {t("Clone")}
                  </Button>
                </Modal.Footer>
              </Form>
            )
          }}
        </Formik>
      </Modal>

      {isErrorModalOpen ?
        <ErrorModalComponent
          i18n={i18next}
          onClose={() => {
            setIsErrorModalOpen(null)
          }}
          isOpen={!!isErrorModalOpen}
          currentError={isErrorModalOpen}
        /> : null
      }
    </>
  );
};

export default ModalCloneMenuItem;
