import React from 'react';
import {Modal, Button, Col} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface ConfirmDeleteModalProps {
    show: boolean;
    handleClose: () => void;
    onConfirm: () => void;
    groupName: string;
}

const ConfirmDeleteModal: React.FC<ConfirmDeleteModalProps> = (
    {
        show,
        handleClose,
        onConfirm,
        groupName,
    }) => {
    const {t} = useTranslation();

    return (
        <Modal centered show={show} onHide={handleClose}>
            <Modal.Header closeButton className="border-0">
                <Modal.Title className="fs-5">{t('Delete option "{{groupName}}"', {
                    groupName})}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="mx-auto col-sm-10">
                    <p className="text-center mb-0">
                        {t('Are you sure you want to remove the option from this menu?')}
                    </p>
                </div>
            </Modal.Body>
            <Modal.Footer className="border-0">
                <Col>
                    <Button variant="outline-primary" className="w-100 h-100" onClick={handleClose}>
                        {t("cancel")}
                    </Button>
                </Col>
                <Col>
                    <Button variant="primary" className="w-100 fw-bold" onClick={onConfirm}>
                        {t("delete")}
                    </Button>
                </Col>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmDeleteModal;
