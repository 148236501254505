import React from 'react';
import {Button} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {TooltipCustom} from '../index';

interface BadgeAndButtonForArchiveProps {
  onButtonClick: () => void;
  hideButton?: boolean;
}

const BadgeAndButtonForArchive: React.FC<BadgeAndButtonForArchiveProps> = ({
                                                                             onButtonClick,
                                                                             hideButton
                                                                           }) => {
  const {t} = useTranslation();

  return (
    <div className="d-flex align-items-center gap-2">
      <span
        className='badge bg-additional-grey fs-7 text-grey px-3 py-2 rounded mb-xl-0 d-flex justify-content-center align-items-center'
      >
        {t('Archive')}
      </span>
      {
        !hideButton ?
          <TooltipCustom placement='bottom' text={t('Restore')}>
            <Button
              className='btn btn-outline-dark btn-square border-secondary align-middle p-2'
              variant='outline-light'
              style={{width: '39px', height: '39px'}}
              onClick={onButtonClick}
            >
              <i className='bi bi-arrow-counterclockwise text-dark'></i>
            </Button>
          </TooltipCustom>
          : null
      }
    </div>
  );
};

export default BadgeAndButtonForArchive;