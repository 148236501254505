import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    TableHeader,
    ReactSelect,
    ProgressBar,
    InfoBanner, ModalDelete,
} from '@services/ui-components';
import {Row, Col, Button, OverlayTrigger, Tooltip, Badge} from 'react-bootstrap';
import {useParams} from "react-router-dom";
import {useGetMenuCategoriesQuery} from '@me-team/host/src/modules/menu/graphql/menu.hooks';
import {getSteps, IStep} from '../MenuItems/constants';
import balanceImage from "@me-team/common/src/images/icones/balance.png";
import menuImage from "@me-team/common/src/images/empty-menu-dish.png";

interface ExpandedCategoriesState {
    [key: number]: boolean;
}

const MenuEditContent: React.FC = () => {
    const {t} = useTranslation();
    const { id } = useParams();
    const basePath = process.env.REACT_APP_POS_URL;
    const steps: IStep[] = getSteps(t);
    const [expandedCategories, setExpandedCategories] = useState<ExpandedCategoriesState>({});
    const [showModalDelete, setShowModalDelete] = useState<boolean>(false);

    const toggleExpandCategory = (categoryId: number) => {
        setExpandedCategories((prev) => ({
            ...prev,
            [categoryId]: !prev[categoryId],
        }));
    };

    const { data: categoriesData, loading, error  } = useGetMenuCategoriesQuery();
    const categories = categoriesData?.user?.company?.meMenuCategoriesPaginated?.categories || [];

    const handleShowModalDelete = () => setShowModalDelete(true);
    const handleModalDeleteClose = () => setShowModalDelete(false);

    // Mock data dishes
    const DishesData = [
        {
            name: "Red Borsch",
            comment: "Ukrainian traditional red Borsch with beef. Served with bun and bacon.",
            image: "https://me-pos.com/storage/blog/blog2-blog66fa75782a6812.01832878.png",
            ingredients: "Water, beef, carrots, onion, potatoes, cabbage, tomato paste, salt, black pepper, bun, bacon.",
            additives: ["Оберіть соус", "До борщу"],
            price: "200 / 250",
            volume: "200",
            time: 30
        },

        {
            name: "Steamed Potatoes with Vegetables",
            comment: "Steamed potatoes served with fresh vegetables in a sauce.",
            image: "https://me-pos.com/storage/blog/blog2-blog66fa75782a6812.01832878.png",
            ingredients: "Bacon, onions, cream, milk, eggs, garlic, curry powder, bay leaf, beef, butter, cauliflower, cheese, chicken, eggs, leeks, mayonnaise, mushrooms",
            additives: [],
            price: "250",
            volume: "200",
            time: null
        },

        {
            name: "Steamed Potatoes",
            comment: "Steamed potatoes served with fresh vegetables in a sauce.",
            image: "https://me-pos.com/storage/blog/blog2-blog66fa75782a6812.01832878.png",
            ingredients: "Bacon, onions, cream, milk, eggs, garlic, curry powder, bay leaf, beef, butter, cauliflower, cheese, chicken, eggs, leeks, mayonnaise, mushrooms",
            additives: [],
            price: "200",
            volume: "150",
            time: 20
        },
    ];

    return (
        <div className="mb-4">
            <TableHeader
                title={t("Menu for establishment")}
                paths={[{path: "/branches", label: t("Branches")}, {path: "/menu/page", label: t("All menu")}, {path: `/menu/page/${id}`, label: t("Menu for establishment")}]}
                buttons={
                    <Row className='gx-3 justify-content-end mt-3 mt-lg-0'>
                        <Col sm={6} lg={5} className="mb-2 mb-xxl-0">
                            <div className="position-relative">
                                <span className="position-absolute start-0 top-0 z-1 ps-2 pt-1 fs-8 text-grey">{t("Language version")}</span>
                                <ReactSelect
                                    id="listOfDishes-language-field"
                                    value={{value: 'Українська (основна)', label: 'Українська (основна)'}}
                                    options={[]}
                                    placeholder={t("Type of branch")}
                                    onChange={() => {
                                    }}
                                    isSearchable={true}
                                    disabled={false}
                                    isMeMenu={true}
                                    isTitle={true}
                                />
                            </div>
                        </Col>
                        <Col sm={6} lg={7} xxl={5}>
                            <Button className="btn btn-primary fw-bold w-100">{t('Save and continue')}</Button>
                        </Col>
                    </Row>
                }
                colSize={6}
                btnColSize={6}
            ></TableHeader>
            <ProgressBar currentStep={2} steps={steps} marginBottom="mb-0"/>

            {/* Show this info block if failed to move the dish*/}
            {/*<InfoBanner backgroundColor="danger-light" textColor="danger" classIcon="bi-exclamation-circle" marginBottom="mb-0" isCloseButton>*/}
            {/*    {t("Failed to move the dish to another category")}*/}
            {/*</InfoBanner>*/}

            <Row className="gx-5">
                <Col xl={5} className="mb-4 mb-xl-0">
                    <Row className="justify-content-between align-items-center mb-4">
                        <Col sm={6} className="mb-3 mb-sm-0">
                            <h3 className="fs-24 mb-0">{t("Category")}</h3>
                        </Col>
                        <Col sm={6} md={5} lg={4} xl={6} className="text-end">
                            <Button className="w-100 py-1 px-3" variant="primary">
                                <i className="bi bi-plus-lg me-2 fs-16"></i>
                                <span className="fs-7 fw-bold">{t("Add category")}</span>
                            </Button>
                        </Col>
                    </Row>

                    {
                        categories.map((category, index) => (
                            <React.Fragment>
                                <div key={category.id} className={`py-3 ${index !== categories.length - 1 ? "border-bottom" : "border-bottom-0"}`}>
                                    <button className="btn btn-category w-100 d-flex justify-content-between align-items-center p-6 rounded-1">
                                        <div className="d-flex align-items-center">
                                            <button className="btn p-1 me-2" onClick={() => toggleExpandCategory(category.id)}>
                                                {category.childCategories.length !== 0 ? (
                                                    expandedCategories[category.id] ? (
                                                        <i className="bi bi-caret-down-fill"></i>
                                                    ) : (
                                                        <i className="bi bi-caret-right-fill"></i>
                                                    )
                                                ) : null}
                                            </button>
                                            {category?.favicon ? (
                                                <img className="me-2" src={`${basePath}/${category.favicon}`} alt={category.name} width={20} height={20}/>
                                            ) : null}
                                            <p className="fs-16 mb-0">{category.name}</p>
                                        </div>
                                        <div className="d-flex align-items-center ms-3">
                                            <Button variant="link" className="text-dark p-0 me-3">
                                                <i className="bi bi-pencil text-dark"></i>
                                            </Button>
                                            <Button variant="link" className="text-dark p-0"
                                                    onClick={() => handleShowModalDelete()}>
                                                <i className="bi bi-trash text-dark"></i>
                                            </Button>
                                            <div className="d-flex border-start border-secondary ps-3 ms-3">
                                                <button className="btn btn-icon p-0 me-3" disabled={index === 0}>
                                                    <i className="bi bi-chevron-up fs-16"></i>
                                                </button>
                                                <button className="btn btn-icon p-0" disabled={index === categories.length - 1}>
                                                    <i className="bi bi-chevron-down fs-16"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </button>
                                </div>
                                {
                                    expandedCategories[category.id] && category.childCategories.length > 0 && (
                                        category.childCategories.map((sub) => (
                                            <div key={sub.id} className={`ms-3 py-2 ${index !== sub.length - 1 ? "border-bottom" : "border-bottom-0"}`}>
                                                <button className="btn btn-category w-100 d-flex justify-content-between align-items-center p-6 rounded-1">
                                                    <div className="d-flex align-items-center">
                                                        <p className="fs-16 mb-0">{sub.name}</p>
                                                    </div>
                                                    <div className="d-flex align-items-center ms-3">
                                                        <Button variant="link" className="text-dark p-0 me-3">
                                                            <i className="bi bi-pencil text-dark"></i>
                                                        </Button>
                                                        <Button variant="link" className="text-dark p-0">
                                                            <i className="bi bi-trash text-dark"></i>
                                                        </Button>
                                                        <div className="d-flex border-start border-secondary ps-3 ms-3">
                                                            <button className="btn btn-icon p-0 me-3" disabled={index === 0}>
                                                                <i className="bi bi-chevron-up fs-16"></i>
                                                            </button>
                                                            <button className="btn btn-icon p-0" disabled={index === sub.length - 1}>
                                                                <i className="bi bi-chevron-down fs-16"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </button>
                                            </div>
                                        )
                                    ))
                                }
                            </React.Fragment>
                        ))
                    }
                </Col>

                <Col xl={7}>
                    <Row className="d-flex justify-content-between align-items-center mb-4">
                        <Col lg={6} xl={4} xxl={5} className="mb-4 mb-lg-0">
                            {/*Add name of the category that was selected */}
                            <h3 className="fs-24 mb-0">Перші страви</h3>
                        </Col>
                        <Col lg={6} xl={8} xxl={7} className="d-flex">
                            <Button className="w-100 py-1 px-3 me-3" variant="outline-primary" disabled={DishesData.length === 0}>
                                <i className="bi bi-plus-lg me-2 fs-16"></i>
                                <span className="fs-7">{t("Add dish")}</span>
                            </Button>
                            <Button className="w-100 py-1 px-3" variant="primary">
                                <i className="bi bi-plus-lg me-2 fs-16"></i>
                                <span className="fs-7 fw-bold">{t("Create dish")}</span>
                            </Button>
                        </Col>
                    </Row>

                    <div className="d-flex flex-column gap-3 mb-4">
                        {
                            DishesData.length !== 0 ? (
                                DishesData.map((item) => (
                                    <div className="rounded border border-secondary p-3">
                                        <div className="d-flex justify-content-between">
                                            <div className="d-flex flex-column flex-xxl-row">
                                                <div className="img-menu me-4">
                                                    <img className="object-fit-cover rounded-1" alt="Dish" src={item.image} width={110} height={110}></img>
                                                </div>
                                                <div className="my-3 my-xxl-0 pe-4">
                                                    <div className="d-flex gap-3 align-items-start">
                                                        <h4 className="fs-16 mb-0">{item.name}</h4>
                                                        {/* Block with icons */}
                                                        <div className="d-flex align-items-start gap-2">
                                                            <OverlayTrigger placement="bottom" overlay={<Tooltip>{t('Edit')}</Tooltip>}>
                                                                <Button
                                                                    variant='violet-light'
                                                                    className="d-flex justify-content-center align-items-center text-primary px-1 py-0 rouded-1"
                                                                >
                                                                    <i className="bi bi-pencil fs-7"></i>
                                                                </Button>
                                                            </OverlayTrigger>
                                                            <OverlayTrigger placement="bottom" overlay={<Tooltip>{t('Remove from menu')}</Tooltip>}>
                                                                <Button
                                                                    variant='violet-light'
                                                                    className="d-flex justify-content-center align-items-center text-primary px-1 py-0 rouded-1"
                                                                >
                                                                    <i className="bi bi-trash fs-7"></i>
                                                                </Button>
                                                            </OverlayTrigger>
                                                            <OverlayTrigger placement="bottom" overlay={<Tooltip>{t('Move dish')}</Tooltip>}>
                                                                <Button
                                                                    variant='violet-light'
                                                                    className="d-flex justify-content-center align-items-center text-primary px-1 py-0 rouded-1"
                                                                >
                                                                    <i className="bi bi-box-arrow-right fs-7"></i>
                                                                </Button>
                                                            </OverlayTrigger>
                                                            <OverlayTrigger placement="bottom" overlay={<Tooltip>{t('Clone')}</Tooltip>}>
                                                                <Button
                                                                    variant='violet-light'
                                                                    className="d-flex justify-content-center align-items-center text-primary px-1 py-0 rouded-1"
                                                                >
                                                                    <i className="bi bi-stickies fs-7"></i>
                                                                </Button>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </div>
                                                    <p className="fs-7 text-grey mt-1">{item.comment}</p>
                                                    <p className="fs-7 text-line-clamp-2">{item.ingredients}</p>
                                                    {item.additives.length > 0 && (
                                                        <div>
                                                            <h5 className="fs-7 fw-bold mb-2">{t("Additives")}</h5>
                                                            <div className="d-flex gap-3">
                                                                {item.additives.map((elem) => (
                                                                    <Badge bg="additional-grey"
                                                                           className="rounded-1 p-2 fs-7 text-dark d-flex align-items-center">{elem}</Badge>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <Col xxl={3}>
                                                    <p className="fs-16 fw-bold text-xxl-nowrap text-start text-xxl-end mb-2">{item.price} UAH</p> {/* Add currency sign */}
                                                    {item.time && (
                                                        <div className="d-flex align-items-center justify-content-start justify-content-xxl-center py-1">
                                                            <i className="bi bi-clock-history text-grey fs-16 me-2"></i>
                                                            <span className="fs-7">{item.time} min</span>
                                                        </div>
                                                    )}
                                                    <div className="d-flex align-items-center justify-content-start justify-content-xxl-center py-1">
                                                        <img src={balanceImage} className="me-2" alt="Balance" width={16} height={16}/>
                                                        <span className="fs-7">{item.volume} g</span>
                                                    </div>
                                                </Col>
                                            </div>

                                            <div className="d-flex flex-column justify-content-between align-items-stretch ps-3 ms-3 border-start border-secondary">
                                                <Button variant="link" className="text-dark p-0">
                                                    <i className="bi bi-chevron-up"></i>
                                                </Button>
                                                <Button variant="link" className="text-dark p-0">
                                                    <i className="bi bi-chevron-down"></i>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="rounded border border-additional-grey p-3">
                                    <img src={menuImage} className="img-fluid" alt="Menu card empty"/>
                                </div>
                            )
                        }
                        <Col xxl={4} className="mx-auto">
                            {
                                DishesData.length !== 0
                                    ? <Button variant="primary" className="fw-bold w-100">{t('Save and continue')}</Button>
                                    : <Button variant="primary" className="fw-bold w-100"><i className="bi bi-plus-lg me-2 fs-16"></i>{t('Create dish')}</Button>
                            }
                        </Col>
                    </div>
                </Col>
            </Row>

            <ModalDelete
                show={showModalDelete}
                handleClose={handleModalDeleteClose}
                title={t('Delete category')}
                body={`${t(`Are you sure you want to delete the category from the menu? It will be deleted along with its subcategories`)}?`}
                buttons={[
                    {
                        text: t('cancel'),
                        onClick: handleModalDeleteClose,
                        variant: 'btn btn-outline-violet border-primary text-primary fw-normal',
                        className: 'd-flex justify-content-center align-items-center w-100',
                    },
                    {
                        text: t('delete'),
                        onClick: () => {},
                        variant: 'primary',
                        className: 'w-100 fw-bold',
                    },
                ]}
            />
        </div>
    )
}

export default MenuEditContent;

