import * as Yup from 'yup';
import {TFunction} from 'i18next';

export const menuItemValidationSchema = (t: TFunction) => Yup.object().shape({
  name: Yup.string(),
  type: Yup.string(),
  categories: Yup.array().min(1, t('validation.required')),
  description: Yup.string(),
  portions: Yup.array().of(
    Yup.object().shape({
      name: Yup.string()
        .required(t('validation.required')),
      volume: Yup.number()
        .required(t('validation.required')),
      price: Yup.number()
        .required(t('validation.required')),
    })
  ).min(1, t('validation.required')),
});
